import React, { useEffect } from 'react';
import { gsap } from 'gsap/all';
import { HeroTextContainer, Hide } from '../../../styles/styles';
import { useGlobalStateContext } from '../../../utils/globalContext';
import { de, globalGerman } from '../../../utils/locales/de';
import { en, globalEnglish } from '../../../utils/locales/en';
import { sceneOneAnimation } from './animation';
import {
  BubbleContainerThree,
  BubbleContainer,
  BubbleContainerTwo,
  ScreenOneContainer,
} from './styles';

const Intro = () => {
  const { currentLanguage, currentStation } = useGlobalStateContext();
  const t =
    currentLanguage === 'english' ? en[currentStation - 1] : de[currentStation - 1];
  const globalText = currentLanguage === 'english' ? globalEnglish : globalGerman;

  const t1 = gsap.timeline();
  useEffect(() => {
    // const t1 = gsap.timeline({ delay: 1 });
    sceneOneAnimation(t1);
  }, [t1]);

  // Heading Text split @
  const headingText = globalText.headingOne.split('@');
  const headingTextOne = headingText[0];
  const headingTextTwo = headingText[1];
  // Speech Bubble Text One
  const bubbleTextOne = t.bubbleTextOne;
  // Speech Bubble Text Two
  const bubbleTextTwo = t.bubbleTextTwo;
  // Speech Bubble Text Three
  const bubbleTextThree = t.bubbleTextThree;
  return (
    <>
      <ScreenOneContainer className='screen-Container-1'>
        <HeroTextContainer className='hero-Container-1'>
          <Hide>
            <h1 className='hero-text hero-text-1'>
              <span>{headingTextOne}</span>
            </h1>
          </Hide>
          <Hide>
            <h1 className='hero-text hero-text-2'> {headingTextTwo}</h1>
          </Hide>
        </HeroTextContainer>

        <BubbleContainer className='bubble-container bubble-container-1'>
          <Hide>
            <h1
              className='title bubble-title-1'
              dangerouslySetInnerHTML={{ __html: bubbleTextOne }}
            />
          </Hide>
        </BubbleContainer>
        <BubbleContainerTwo className='bubble-container bubble-container-2'>
          <Hide>
            <h1
              className='title bubble-title-2'
              dangerouslySetInnerHTML={{ __html: bubbleTextTwo }}
            ></h1>
          </Hide>
        </BubbleContainerTwo>
        <BubbleContainerThree className='bubble-container bubble-container-3'>
          <Hide>
            <h1
              className='title bubble-title-3'
              dangerouslySetInnerHTML={{ __html: bubbleTextThree }}
            ></h1>
          </Hide>
        </BubbleContainerThree>
      </ScreenOneContainer>
    </>
  );
};

export default Intro;
