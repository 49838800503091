import { Back, Power3 } from 'gsap/all';

export const sceneThreeAnimation = (t1) => {
  t1.fromTo(
    '.camera-tip-container',
    {
      display: 'flex',
    },
    {
      display: 'none',
      opacity: 0,
    }
  );
  t1.fromTo(
    '.screen-container-3',
    {
      visibility: 'hidden',
    },
    {
      visibility: 'visible',
      duration: 0.1,
    }
  );
  t1.fromTo(
    '.hero-Container-3',
    {
      display: 'none',
    },
    {
      display: 'flex',
      duration: 0.1,
    }
  );
  t1.fromTo(
    '.hero-text',
    {
      opacity: 0,
      x: -100,
      display: 'none',
    },
    {
      display: 'inline-block',

      opacity: 1,
      duration: 1,
      x: 0,
      delay: function (i) {
        return 0.2 * i;
      },
    }
  );

  t1.fromTo(
    '.question-container-1',
    {
      opacity: 0,
    },
    {
      opacity: 1,
      duration: 0.1,
    },
    '-=1'
  );
  t1.fromTo(
    '.question-container-1',
    {
      // opacity: 0,
      width: '10%',
      opacity: 0,

      clipPath: 'circle(7% at 50% 50%) ',
    },
    {
      opacity: 0.9,
      duration: 0.8,
      transformOrigin: 'center center',
      width: '10%',
      ease: Power3.easeInOut,
      clipPath: 'circle(7% at 50% 50%) ',
    },
    '-=1'
  );
  t1.fromTo(
    '.question-container-1',
    {
      // opacity: 0,
      width: '10%',
      transformOrigin: 'top left',
      clipPath: 'circle(7% at 50% 50%) ',
    },
    {
      duration: 0.8,
      width: '100%',
      transformOrigin: 'top left',
      ease: Power3.easeInOut,
      clipPath: 'circle(100% at 50% 50%)     ',
    },
    '-=1'
  );

  t1.fromTo(
    '.question-title-1',
    {
      opacity: 0,
      y: 100,
    },
    {
      opacity: 1,
      duration: 0.8,
      y: 0,
      ease: Back.easeInOut.config(1.7),

      delay: 0.15,
    },
    '-=1'
  );

  t1.fromTo(
    '.question-container-2',
    {
      opacity: 0,
    },
    {
      opacity: 1,
      duration: 0.1,
    },
    '-=.4'
  );
  t1.fromTo(
    '.question-container-2',
    {
      // opacity: 0,
      width: '10%',
      opacity: 0,

      clipPath: 'circle(7% at 50% 50%) ',
    },
    {
      opacity: 0.9,
      duration: 0.8,
      transformOrigin: 'center center',
      width: '10%',
      ease: Power3.easeInOut,
      clipPath: 'circle(7% at 50% 50%) ',
    },
    '-=.5'
  );
  t1.fromTo(
    '.question-container-2',
    {
      // opacity: 0,
      width: '10%',
      transformOrigin: 'top left',
      clipPath: 'circle(7% at 50% 50%) ',
    },
    {
      duration: 0.8,
      width: '100%',
      transformOrigin: 'top left',
      ease: Power3.easeInOut,
      clipPath: 'circle(100% at 50% 50%)     ',
    },
    '-=.5'
  );

  t1.fromTo(
    '.question-title-2, .camera-icon',
    {
      y: 100,
      opacity: 0,
    },
    {
      duration: 0.6,
      y: 0,
      opacity: 1,

      ease: Power3.easeInOut,

      delay: 0.15,
    },
    '-=.5'
  );
  t1.fromTo(
    '.hero-text',

    {
      opacity: 1,
      duration: 0.5,
      x: 0,
      delay: function (i) {
        return 0.2 * i;
      },
    },
    {
      opacity: 0,
      x: -100,
      delay: 1.5,
    }
  );
  return t1;
};
