import React from 'react';
import { gsap, Back, Power3 } from 'gsap/all';
import Menu from './Menu';
import { HeaderContainer } from './styles';

const Header = () => {
  const menuHandler = (e) => {
    if (!e.target.classList.contains('active')) {
      e.target.classList.add('active');
      gsap.to('.header-nav__burger--line1', 0.5, {
        rotate: '45',
        y: 8.5,
      });
      gsap.to('.header-nav__burger--line2', 0.5, {
        opacity: 0,
      });
      gsap.to('.header-nav__burger--line3', 0.5, {
        rotate: '-45',
        y: -5,
      });

      gsap.to('.menu-container', 1, {
        y: -0,
        // ease: Back.easeInOut.config(1.7),
        ease: Power3.easeInOut,
      });
      document.body.classList.add('hide');
    } else {
      e.target.classList.remove('active');

      gsap.to('.header-nav__burger--line1', 0.5, {
        rotate: '0',
        y: 0,
      });
      gsap.to('.header-nav__burger--line2', 0.5, {
        opacity: 1,
      });
      gsap.to('.header-nav__burger--line3', 0.5, {
        rotate: '0',
        y: 0,
      });
      gsap.to('.menu-container', 1, {
        y: '-150%',
        ease: Back.easeInOut.config(1.7),
      });
      document.body.classList.remove('hide');
    }
  };

  return (
    <>
      <HeaderContainer className='header'>
        <img src='/ui/svg/common/logo.svg' className='header-logo' alt='siemens logo' />
        <div className='header-nav'>
          <div className='header-nav__burger' onClick={menuHandler}>
            <div className='header-nav__burger--line1'></div>
            <div className='header-nav__burger--line2'></div>
            <div className='header-nav__burger--line3'></div>
          </div>

          <img
            src='/ui/svg/common/mobility-logo.svg'
            className='header-nav__marker'
            alt='header logo'
          />
        </div>
      </HeaderContainer>
      <Menu />
    </>
  );
};

export default Header;
