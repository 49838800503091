import React, { useEffect } from 'react';
import { gsap } from 'gsap/all';
import { HeroTextContainer, Hide } from '../../../styles/styles';
import {
  useGlobalDispatchContext,
  useGlobalStateContext,
} from '../../../utils/globalContext';
import { de, globalGerman } from '../../../utils/locales/de';
import { en, globalEnglish } from '../../../utils/locales/en';
import { ScreenOneContainer, QuestionContainer, QuestionContainerTwo } from './styles';
import { sceneThreeAnimation } from './animation';

const Intro3 = () => {
  const { currentLanguage, currentStation } = useGlobalStateContext();
  const dispatch = useGlobalDispatchContext();

  const t =
    currentLanguage === 'english' ? en[currentStation - 1] : de[currentStation - 1];
  const handleCamera = () => {
    dispatch({ type: 'TOGGLE_CAMERA', showCamera: true });
  };
  useEffect(() => {
    const t1 = gsap.timeline();
    sceneThreeAnimation(t1);
  }, []);
  // Heading Text
  const globalText = currentLanguage === 'english' ? globalEnglish : globalGerman;

  const headingThree = globalText.headingThree.split('@');
  // Questions  Text One and Two and Three
  const questionTextOne = t.questionTextOne;
  const questionCameraText = globalText.questionCameraText.split('@');
  // const highlighTextTwo = t.questionTextTwo.match(/@(.*?)@/i)[1];

  return (
    <ScreenOneContainer className='screen-Container-3'>
      <HeroTextContainer className='hero-Container-3'>
        <Hide>
          <h1 className='hero-text hero-text-1'>
            <span>{headingThree[0]}</span>
          </h1>
        </Hide>
        <Hide>
          <h1 className='hero-text hero-text-2'> {headingThree[1]}</h1>
        </Hide>
      </HeroTextContainer>
      <QuestionContainer className='question-container question-container-1'>
        <Hide>
          <h1
            className='title question-title-1'
            dangerouslySetInnerHTML={{ __html: questionTextOne }}
          />
        </Hide>
      </QuestionContainer>
      <QuestionContainerTwo className='question-container question-container-2'>
        <div className='question-container-share' onClick={handleCamera}>
          <Hide
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src='/ui/svg/common/camera-icon.svg'
              className='camera-icon'
              alt='text background'
              style={{ alignSelf: 'start' }}
            />
            <div>
              {questionCameraText?.map((text, i) => (
                <Hide key={i}>
                  <h1 className='title question-title-2'>{text}</h1>
                </Hide>
              ))}
            </div>
          </Hide>
        </div>
      </QuestionContainerTwo>
    </ScreenOneContainer>
  );
};

export default Intro3;
