import React from 'react';
import { Hide } from '../../../styles/styles';
import {
  useGlobalDispatchContext,
  useGlobalStateContext,
} from '../../../utils/globalContext';

import { useLocation } from 'react-router-dom';
import { CameraToolTip, FooterContainer } from './styles';
import { globalEnglish, en } from '../../../utils/locales/en';
import { globalGerman, de } from '../../../utils/locales/de';

const Footer = () => {
  const location = useLocation();
  const { currentLanguage, currentStation } = useGlobalStateContext();
  const dispatch = useGlobalDispatchContext();

  const t = currentLanguage === 'english' ? globalEnglish : globalGerman;

  const cameraTollTip = t.cameraTollTip.split('@');

  const locations =
    location?.pathname !== '/' ||
    location?.pathname !== '/3' ||
    location?.pathname !== '/4';
  const handleCamera = () => {
    dispatch({ type: 'TOGGLE_CAMERA', showCamera: true });
  };
  const stationText =
    currentLanguage === 'english' ? en[currentStation - 1] : de[currentStation - 1];
  const stationLink =
    currentLanguage === 'english'
      ? `https://new.siemens.com/global/en/company/fairs-events/fairs/iaa/mobility-movers/${stationText?.link}.html`
      : `https://new.siemens.com/de/de/unternehmen/messen-events/messen/iaa/mobility-movers/${stationText?.link}.html`;
  return (
    <>
      {location.pathname !== '/' && (
        <FooterContainer
          className='footer-container'
          style={locations ? { opacity: 1 } : { opacity: 0 }}
        >
          <div className='footer-nav'>
            <a href={stationLink} target='_blank' rel='noreferrer'>
              {t.footerText}
            </a>
            <img
              src='/ui/svg/common/camera-icon.svg'
              className='footer-image'
              alt='camera'
              onClick={handleCamera}
            />
          </div>

          {locations && (
            <CameraToolTip className='camera-tip-container'>
              <div className='bubble-content bubble-content-footer'>
                <div className='bubble-content__heading'>
                  {currentStation &&
                    cameraTollTip?.map((text, i) => (
                      <Hide key={i}>
                        <h1 className='title footer-title'>{text}</h1>
                      </Hide>
                    ))}
                </div>
              </div>
            </CameraToolTip>
          )}
        </FooterContainer>
      )}
    </>
  );
};

export default Footer;
