import styled from 'styled-components';
// Header Styles
export const HeaderContainer = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--primary);
  padding: 1.8rem 1rem;
  @media screen and (max-height: 568px) {
    padding: 0.5rem 1rem;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 220;
  .header-logo {
    flex: 0 0 35%;
  }
  .header-nav {
    flex: 0 0 24%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__burger {
      &--line1,
      &--line2,
      &--line3 {
        height: 0.2rem;
        width: 2rem;
        background: var(--white);
        margin: 0.5rem;
        pointer-events: none;
      }
      &--line2 {
        width: 1.5rem;
      }
    }
    &-marker {
      padding-left: 2rem;
    }
  }
`;
