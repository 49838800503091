import Intro3 from '../components/SC/Intro3';

const Screen3 = () => {
  return (
    <>
      <Intro3 />
    </>
  );
};
export default Screen3;
