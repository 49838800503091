import React from 'react';
import Button from '../../Button';
import { InfoContainer } from './styles';

const Info = ({ item }) => {
  const text = item.text.split('@');
  const { contact } = item;

  return (
    <InfoContainer className='info-container'>
      <div className='info'>
        <p className='info-title'>{item.title}</p>
        <img
          src='/ui/svg/common/close-icon.svg'
          className='info-cancel'
          alt='Close'
          onClick={item.open}
        />
      </div>
      <div className='desc'>
        {text &&
          text.map((item, i) => (
            <p className='desc-text' key={i}>
              {item}
            </p>
          ))}
        {contact !== null && (
          <div className='desc-contact'>
            <p className='desc-text desc-contact-email'>{contact.email}</p>
            <p className='desc-text'>{contact.tel}</p>
            <p className='desc-text'>{contact.fax}</p>
          </div>
        )}
      </div>

      <Button link={item.link} text={item.buttonText} />
    </InfoContainer>
  );
};
export default Info;
