import styled from 'styled-components';

export const ScreenOneContainer = styled.div`
  z-index: 100;
  position: absolute;
  top: 5%;
  width: 100%;
  height: 50%;
  visibility: 'hidden';
  @media screen and (max-height: 640px) {
    top: 3%;
  }
  @media screen and (min-height: 800px) {
    top: 12%;
  }
  @media screen and (max-height: 568px) {
    top: -1%;
  }
`;

export const TextContainer = styled.div`
  position: relative;
  left: 45%;
  top: 11rem;
  max-width: clamp(48%, 45%, 51%);
  @media screen and (min-height: 414px) {
    max-width: clamp(49%, 45%, 51%);
  }
  @media screen and (min-height: 640px) {
    max-width: clamp(56%, 45%, 51%);
    left: 40%;
  }
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  .title {
    /* word-break: break-word; */

    font-size: clamp(1.4rem, 1.6rem, 2rem);
    @media screen and (max-height: 568px) {
      font-size: clamp(1rem, 1.1rem, 1.7rem);
    }
    font-weight: 700;
    line-height: 2rem;
    padding: 1.2rem 1.9rem;
  }
  background: rgba(255, 255, 255, 0.9);

  box-shadow: 0px 7.85714px 8px rgba(0, 0, 40, 0.25);
  border-radius: 20px 0px 20px 20px;
`;

export const TextContainerTwo = styled(TextContainer)`
  top: 26rem;
  left: 6%;
  @media screen and (max-height: 720px) {
    top: 17rem;
  }
  @media screen and (max-height: 760px) {
    top: 20rem;
  }
  @media screen and (max-height: 667px) {
    top: 16rem;
  }

  max-width: clamp(56%, 46%, 52%);
`;
