import { Back, Power2 } from 'gsap/all';

export const sceneTwoAnimation = (t1) => {
  t1.fromTo(
    '.camera-tip-container',
    {
      opacity: 0,
    },
    {
      opacity: 0,
    }
  );
  t1.fromTo(
    '.screen-container-2',
    {
      visibility: 'hidden',
    },
    {
      visibility: 'visible',
      duration: 0.1,
    }
  );
  t1.fromTo(
    '.hero-Container-2',
    {
      display: 'none',
    },
    {
      display: 'flex',
      duration: 0.1,
    }
  );
  t1.fromTo(
    '.hero-text',
    {
      opacity: 0,
      x: -100,
      display: 'none',
    },
    {
      display: 'inline-block',

      opacity: 1,
      duration: 1,
      x: 0,
      delay: function (i) {
        return 0.2 * i;
      },
    }
  );

  // initAnimation();

  t1.fromTo(
    '.text-container-1',
    {
      opacity: 0,
      width: '20%',
    },
    {
      duration: 0.5,
      opacity: 1,

      transformOrigin: '0% 0%',
      delay: 0.5,
      ease: Power2.easeInOut,
    },
    '-=.5'
  );
  t1.fromTo(
    '.text-container-1',
    {
      width: '20%',
      transformOrigin: 'top left',
      clipPath: 'circle(11.7% at 50% 50%) ',
    },
    {
      transformOrigin: '0% 0%',
      duration: 1,
      width: '100%',
      ease: Power2.easeInOut,
      clipPath: 'circle(100% at 50% 50%)     ',
    },
    '-=1'
  );

  t1.fromTo(
    '.wish-title-1',
    {
      opacity: 0,
      y: 100,
    },
    {
      opacity: 1,
      duration: 0.5,
      y: 0,
      ease: Back.easeInOut.config(1.7),

      delay: function (i) {
        return 0.3 * i;
      },
    },
    '-=.5'
  );
  t1.fromTo(
    '.text-container-2',
    {
      // opacity: 0,
      width: '20%',
      opacity: 0,
    },
    {
      opacity: 1,
      duration: 0.5,

      ease: Power2.easeInOut,
    },
    '-=.6'
  );
  t1.fromTo(
    '.text-container-2',
    {
      // opacity: 0,
      width: '20%',
      transformOrigin: 'top left',
      clipPath: 'circle(6% at 27% 25%)',
    },
    {
      duration: 1,
      width: '100%',
      transformOrigin: '0% 0%',
      ease: Power2.easeInOut,
      clipPath: 'circle(100% at 27% 25%)    ',
    },
    '-=.7'
  );

  t1.fromTo(
    '.wish-title-2',
    {
      opacity: 0,
      y: 100,
    },
    {
      opacity: 1,
      duration: 0.5,
      y: 0,
      ease: Back.easeInOut.config(1.7),
      delay: function (i) {
        return 0.3 * i;
      },
    },
    '-=.5'
  );
  t1.fromTo(
    '.hero-text',

    {
      opacity: 1,
      duration: 0.5,
      x: 0,
      delay: function (i) {
        return 0.2 * i;
      },
    },
    {
      opacity: 0,
      x: -100,
      delay: 1.5,
    }
  );
  t1.fromTo(
    '.camera-tip-container',
    {
      opacity: 0,
    },
    {
      zIndex: 100,

      opacity: 1,
    }
  );
  t1.fromTo(
    '.bubble-content-footer',
    {
      opacity: 1,
      width: '40%',
      y: 50,
      visibility: 'hidden',
      clipPath: 'circle(6% at 72% 80%) ',
    },
    {
      opacity: 1,
      transformOrigin: 'right right',
      duration: 0.5,
      visibility: 'visible',
      clipPath: 'circle(6% at 72% 80%) ',
      y: 0,
      delay: 1,
      ease: Back.easeInOut.config(1.7),
    }
  );

  t1.fromTo(
    '.bubble-content-footer',
    {
      width: '40%',
      transformOrigin: 'bottom right',
      clipPath: 'circle(6% at 72% 80%) ',
      opacity: 1,
    },
    {
      transformOrigin: 'bottom right',
      duration: 0.5,
      opacity: 1,
      width: '100%',
      ease: Power2.easeInOut,
      clipPath: 'circle(100% at 72% 80%) ',
    }
  );

  t1.fromTo(
    '.footer-title',
    {
      y: 100,
      opacity: 0,
    },
    {
      opacity: 1,
      duration: 0.5,
      y: 0,
      display: 'block',
      ease: Back.easeInOut.config(1.7),

      delay: function (i) {
        return 0.3 * i;
      },
    }
  );
  return t1;
};
