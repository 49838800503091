import { useState, useEffect } from 'react';
import {
  useGlobalDispatchContext,
  useGlobalStateContext,
} from '../../utils/globalContext';
import { gsap, Power1 } from 'gsap/all';
import { globalEnglish } from '../../utils/locales/en';
import { globalGerman } from '../../utils/locales/de';
import { Hide } from '../../styles/styles';
import { LanguageContainer, MarkersContainer, BoxContainer } from './styles';
import { startMarkerContainer } from './animation';
import { useLocation } from 'react-router-dom';

const LanguageScreen = () => {
  const location = useLocation();
  const dispatch = useGlobalDispatchContext();
  const { currentLanguage, currentStation } = useGlobalStateContext();
  const [selectMarker, setSelectMarker] = useState(false);

  const t = currentLanguage === 'english' ? globalEnglish : globalGerman;
  const [languageStatus, setLanguageStatus] = useState(
    window.localStorage.getItem('languageStatus') === null
      ? false
      : window.localStorage.getItem('languageStatus')
  );

  const t2 = gsap.timeline({ delay: 1 });
  const LanguageHandlerGerman = () => {
    dispatch({ type: 'TOGGLE_LANGUAGE', language: 'german' });
    animateLanguageBox();
    localStorage.setItem('status', true);
  };
  const LanguageHandlerEnglish = () => {
    dispatch({ type: 'TOGGLE_LANGUAGE', language: 'english' });
    animateLanguageBox();
    localStorage.setItem('status', true);
  };
  const animateLanguageBox = () => {
    window.localStorage.setItem('language', currentLanguage);
    setLanguageStatus(window.localStorage.setItem('languageStatus', true));
    gsap.to('.language-container', {
      opacity: 0,
      duration: 0.8,
      y: -100,
      ease: Power1.easeInOut,
      display: 'none',
    });
    gsap.to('.box-container', {
      background: 'none',
      duration: 0.001,
    });
    setTimeout(() => {
      setSelectMarker(true);
      startMarkerContainer(t2);
    }, 1000);
  };
  const t1 = gsap.timeline({ delay: 1 });

  useEffect(() => {
    const languageContainer = document.querySelector('.language-container');
    if (languageContainer) {
      t1.fromTo(
        '.language-container',
        {
          opacity: 0,
          y: 100,
        },
        {
          opacity: 1,
          duration: 0.5,
          y: 0,
          ease: Power1.easeInOut,
          delay: 1.2,
        }
      );
      t1.fromTo(
        '.title-language , .language-container-button',
        {
          opacity: 0,
          y: 100,
        },
        {
          opacity: 1,
          duration: 0.5,
          y: 0,
          ease: Power1.easeInOut,

          delay: function (i) {
            return 0.3 * i;
          },
        }
      );
    }
  });
  const station = location.search.split('=')[1];
  useEffect(() => {
    if (station) {
      dispatch({ type: 'TOGGLE_STATION', station: station });
      window.localStorage.setItem('station', station);
    }
    if (!station) {
      dispatch({ type: 'TOGGLE_STATION', station: 8 });
      window.localStorage.setItem('station', 8);
    }
  }, [station, currentStation]);
  useEffect(() => {
    if (languageStatus) {
      setTimeout(() => {
        // setSelectMarker(true);
        startMarkerContainer(t2);
      }, 1500);
    }
  }, [languageStatus]);
  const markerAdvice = t.markerAdvice.split('@');

  return (
    <>
      {!languageStatus && (
        <BoxContainer className='box-container'>
          <LanguageContainer
            className='language-container'
            style={selectMarker ? { display: 'none' } : { display: 'block' }}
          >
            {['Should I talk to you in', 'English or German?'].map((text, i) => (
              <Hide key={i}>
                <h1 className='title title-language'>{text}</h1>
              </Hide>
            ))}
            <div className='language-container-button'>
              <button
                onClick={LanguageHandlerEnglish}
                rel='noreferrer noopener'
                className='button language-button'
              >
                English
              </button>
              <button
                onClick={LanguageHandlerGerman}
                rel='noreferrer noopener'
                className='button language-button'
              >
                German
              </button>
            </div>
          </LanguageContainer>
        </BoxContainer>
      )}
      {(languageStatus || selectMarker) && (
        <MarkersContainer className='marker-container'>
          {markerAdvice &&
            markerAdvice?.map((text, i) => (
              <Hide key={i}>
                <h1 className='title title-marker'>{text}</h1>
              </Hide>
            ))}
          <div className='marker-container-button'>
            <img src='/ui/svg/markers/marker-3.svg' alt='marker message' />
            <img src='/ui/svg/markers/redo.svg' alt='marker message' />
            <img src='/ui/svg/markers/marker-1.svg' alt='marker message' />
            <img
              src='/ui/svg/markers/redo.svg'
              alt='marker message'
              style={{ transform: 'rotateY(180deg)' }}
            />
            <img src='/ui/svg/markers/marker-2.svg' alt='marker message' />
          </div>
        </MarkersContainer>
      )}
    </>
  );
};
export default LanguageScreen;
