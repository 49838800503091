import React from 'react';
import { ButtonContainer } from './styles';

const Button = ({ link, text }) => {
  return (
    <ButtonContainer
      href={link}
      target='_blank'
      rel='noopener noreferrer'
      className='menu-item'
    >
      <p>{text} </p>
    </ButtonContainer>
  );
};

export default Button;
