import Intro from '../components/SC/Intro';

const Screen1 = () => {
  return (
    <>
      <Intro />
    </>
  );
};
export default Screen1;
