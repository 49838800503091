import styled from 'styled-components';

export const InfoContainer = styled.div`
  background: rgba(255, 255, 255, 0.9);
  width: 90%;
  /* min-height: 60vh; */
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  align-items: space-between;
  justify-content: center;
  flex-direction: column;
  padding: 2.5rem 2rem;
  transform: translateY(10rem);
  opacity: 0;
  @media screen and (max-height: 720px) {
    top: 15%;
    overflow: auto;
  }
  .desc {
    @media screen and (max-height: 720px) {
      overflow: auto;
      max-height: 300px;
    }
    &-text {
      font-size: 1.6rem;
      line-height: 26px;
      width: 95%;
      @media screen and (max-height: 568px) {
        overflow: auto;
      }
    }
    &-contact {
      padding: 1.8rem 0;
      @media screen and (max-height: 568px) {
        padding: 1rem 0 0 0;
      }
      &-email {
        color: var(--secondary);
        cursor: pointer;
        text-decoration: underline;
      }
    }
    padding: 1rem 1rem 1rem;
  }
  .info {
    flex: 1;
    padding: 1rem;
    display: flex;
    text-decoration: none;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;

    &-title {
      color: var(--primary);
      font-size: 2rem;
      font-weight: 700;
      padding: 1rem 0;
    }
    &-cancel {
      justify-self: flex-start;
    }
  }
`;
