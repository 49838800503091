import { Power1 } from 'gsap/all';

export const startMarkerContainer = (t2) => {
  t2.fromTo(
    '.title-marker, .marker-container-button',
    {
      opacity: 0,
      y: 100,
    },
    {
      opacity: 1,
      duration: 0.5,
      y: 0,
      ease: Power1.easeInOut,

      delay: function (i) {
        return 0.3 * i;
      },
    }
  );

  t2.fromTo(
    '.marker-container',
    {
      opacity: 1,
      y: 0,
      display: 'block',
    },
    {
      opacity: 0,
      duration: 0.5,
      y: -100,
      ease: Power1.easeInOut,
      display: 'none',
      delay: 12,
    }
  );
};
