import { gsap, Power2, Back } from 'gsap/all';
gsap.config({
  nullTargetWarn: false,
});

export const sceneOneAnimation = (t1) => {
  t1.fromTo(
    '.screen-container-1',
    {
      visibility: 'hidden',
      opacity: 0,
    },
    {
      visibility: 'visible',
      duration: 0.1,
      opacity: 1,
    }
  );

  t1.fromTo(
    '.hero-Container-1',
    {
      display: 'none',
    },
    {
      display: 'flex',
      duration: 0.1,
    }
  );

  t1.fromTo(
    '.hero-text',
    {
      opacity: 1,

      x: -100,
      visibility: 'hidden',
    },
    {
      visibility: 'visible',

      opacity: 1,
      duration: 1,
      x: 0,
      delay: function (i) {
        return 0.2 * i;
      },
    }
  );

  t1.fromTo(
    '.bubble-container-1',
    {
      opacity: 0,
    },
    {
      opacity: 0.9,
      duration: 0.4,
    }
  );

  t1.fromTo(
    '.bubble-container-1',
    {
      width: '40%',
      y: 250,
      clipPath: 'circle(15% at 50% 50%) ',
    },
    {
      transformOrigin: 'center center',
      duration: 0.8,
      y: 0,
      ease: Power2.easeInOut,
    },
    '-=1'
  );
  t1.fromTo(
    '.bubble-container-1',
    {
      width: '40%',
      transformOrigin: 'top top',
      clipPath: 'circle(15% at 50% 50%) ',
    },
    {
      transformOrigin: 'top left',
      duration: 0.8,
      width: '100%',
      ease: Power2.easeInOut,
      clipPath: 'circle(100% at 50% 50%)     ',
    },
    '-=.5'
  );

  t1.fromTo(
    '.bubble-title-1',
    {
      opacity: 0,
      y: 100,
    },
    {
      opacity: 1,
      duration: 0.6,
      y: 0,
      ease: Back.easeInOut.config(1.7),
      delay: 0.15,
    },
    '-=.5'
  );
  t1.fromTo(
    '.bubble-container-2',
    {
      opacity: 0,
      width: '40%',
      y: 250,
      clipPath: 'circle(15% at 50% 50%) ',
    },
    {
      opacity: 0.9,
      transformOrigin: 'center center',
      duration: 0.8,
      y: 0,
      ease: Back.easeInOut.config(1.7),
    },
    '-=.5'
  );
  t1.fromTo(
    '.bubble-container-2',
    {
      width: '40%',
      transformOrigin: 'top top',
      clipPath: 'circle(15% at 50% 50%) ',
    },
    {
      transformOrigin: 'top left',
      duration: 0.8,
      width: '100%',

      ease: Power2.easeInOut,
      clipPath: 'circle(100% at 50% 50%)     ',
    },
    '-=.5'
  );

  t1.fromTo(
    '.bubble-title-2',
    {
      opacity: 0,
      y: 100,
    },
    {
      opacity: 1,
      duration: 0.8,
      y: 0,
      ease: Back.easeInOut.config(1.7),
      delay: 0.15,
    },
    '-=.5'
  );

  t1.fromTo(
    '.bubble-container-3',
    {
      opacity: 0,
      width: '40%',
      y: 100,
      clipPath: 'circle(15% at 50% 50%) ',
    },
    {
      opacity: 0.9,
      transformOrigin: 'center center',
      duration: 0.8,
      y: 0,
      ease: Back.easeInOut.config(1.7),
    },
    '-=.5'
  );
  t1.fromTo(
    '.bubble-container-3',
    {
      width: '40%',
      transformOrigin: 'top top',
      clipPath: 'circle(15% at 50% 50%) ',
    },
    {
      transformOrigin: 'top left',
      duration: 0.8,
      width: '100%',
      ease: Power2.easeInOut,
      clipPath: 'circle(100% at 50% 50%)     ',
    },
    '-=.5'
  );

  t1.fromTo(
    '.bubble-title-3',
    {
      opacity: 0,
      y: 100,
    },
    {
      opacity: 1,
      duration: 0.6,
      y: 0,
      ease: Back.easeInOut.config(1.7),
      delay: 0.15,
    },
    '-=.5'
  );
  t1.fromTo(
    '.hero-text',

    {
      opacity: 1,
      x: 0,
      delay: function (i) {
        return 0.2 * i;
      },
    },
    {
      opacity: 1,
      x: -100,
      duration: 0.5,
      delay: 0.5,
    }
  );
  t1.fromTo(
    '.camera-tip-container',
    {
      opacity: 0,
    },
    {
      opacity: 1,
      zIndex: 100,
    }
  );
  t1.fromTo(
    '.bubble-content-footer',
    {
      width: '40%',
      y: 50,
      visibility: 'hidden',
      clipPath: 'circle(6% at 72% 80%) ',
    },
    {
      transformOrigin: 'right right',
      duration: 0.5,
      visibility: 'visible',
      clipPath: 'circle(6% at 72% 80%) ',

      y: 0,
      delay: 0.3,
      ease: Back.easeInOut.config(1.7),
    },
    '-=.5'
  );

  t1.fromTo(
    '.bubble-content-footer',
    {
      width: '40%',
      transformOrigin: 'bottom right',
      clipPath: 'circle(6% at 72% 80%) ',
    },
    {
      transformOrigin: 'bottom right',
      duration: 0.5,

      width: '100%',
      ease: Power2.easeInOut,
      clipPath: 'circle(100% at 72% 80%) ',
    },
    '-=.5'
  );

  t1.fromTo(
    '.footer-title',
    {
      y: 100,
      opacity: 0,
    },
    {
      opacity: 1,
      duration: 0.5,
      y: 0,
      display: 'block',
      ease: Back.easeInOut.config(1.7),

      // delay: function (i) {
      //   return 0.3 * i;
      // },
    },
    '-=.5'
  );

  return t1;
};
