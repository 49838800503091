import styled from 'styled-components';

export const ButtonContainer = styled.a`
  @media screen and (max-height: 720px) {
    margin-top: 1.5rem;
  }
  p {
    border: 1px solid var(--secondary-dark);
    font-size: 1.6rem;
    padding: 0.6rem 4rem;
    color: var(--secondary-dark);
    font-weight: 700;
    display: inline-block;
  }
`;
