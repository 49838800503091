import styled from 'styled-components';

export const ScreenOneContainer = styled.div`
  z-index: 100;
  position: absolute;
  top: 5%;
  width: 100%;
  height: 60%;
  visibility: 'hidden';
  @media screen and (max-height: 640px) {
    top: 3%;
  }
  @media screen and (min-height: 800px) {
    top: 12%;
  }
  @media screen and (max-height: 568px) {
    top: -3%;
  }
`;

export const QuestionContainer = styled.div`
  position: relative;
  left: 32%;
  top: 30%;
  opacity: 0;
  @media screen and (max-height: 760px) {
    top: 30%;
  }
  @media screen and (max-height: 568px) {
    top: 35%;
  }
  max-width: clamp(60%, 52%, 78%);
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  box-shadow: 0px 7.85714px 8px rgba(0, 0, 40, 0.25);

  .title {
    font-size: clamp(1.4rem, 1.6rem, 2rem);
    @media screen and (max-height: 568px) {
      font-size: clamp(1rem, 1.2rem, 1.7rem);
    }
    font-weight: 700;
    line-height: 2rem;
    padding: 1.2rem 1.9rem;
  }
  background: rgba(255, 255, 255, 0.9);

  box-shadow: 0px 7.85714px 8px rgba(0, 0, 40, 0.25);
  border-radius: 20px 0px 20px 20px;
`;

export const QuestionContainerTwo = styled(QuestionContainer)`
  top: 55%;
  left: 6%;
  @media screen and (max-height: 736px) {
    top: 50%;
  }
  @media screen and (max-height: 667px) {
    top: 50%;
  }
  @media screen and (max-height: 568px) {
    top: 52%;
  }
  @media screen and (max-height: 480px) {
    top: 55%;
  }

  .question-container-share {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.2rem 1rem;
    @media screen and (max-height: 480px) {
      padding: 0.5rem 1rem;
    }
    cursor: pointer;
    & > * {
      pointer-events: none;
    }
    .camera-icon {
      /* margin-top: 1.8rem; */
      @media screen and (min-width: 400px) {
        margin-top: 0rem;
      }
    }
    .question-title-2 {
      margin-left: 0.5rem;
      font-size: clamp(1.4rem, 1.6rem, 1.8rem);
      @media screen and (max-height: 568px) {
        font-size: clamp(1rem, 1.2rem, 1.7rem);
      }
      opacity: 0.7;
      color: #0098a6;
      padding: 0.2rem 0.5rem;
    }
  }
  max-width: clamp(60%, 64%, 78%);
`;
