// A React component for 8th Wall AFrame scenes. The scene HTML can be supplied, along with

import { useEffect } from 'react';
import './main-component';
import './particle-component';
import './smart-recenter';
// any components or primitives that should be registered, and any image targets that should be

// loaded if something other than the automatically loaded set is wanted. Passing

// DISABLE_IMAGE_TARGETS will prevent any image targets from loading, including ones that would

// otherwise enabled automatically.

const AFRAME = window.AFRAME;
let XR8 = window.XR8;
// Helper function to make sure that aframe components are only registered once, since they can't

// be cleanly unregistered.

const registeredComponents = new Set();

const registerComponents = (components) =>
  components.forEach(({ name, val }) => {
    if (registeredComponents.has(name)) {
      return;
    }

    registeredComponents.add(name);
    AFRAME.registerComponent(name, val);
  });

// Helper function to make sure that aframe primitives are only registered once, since they can't

// be cleanly unregistered.

const registeredPrimitives = new Set();

const registerPrimitives = (primitives) =>
  primitives.forEach(({ name, val }) => {
    if (registeredPrimitives.has(name)) {
      return;
    }

    registeredPrimitives.add(name);

    AFRAME.registerPrimitive(name, val);
  });

function AFrameScene({ sceneHtml, components, primitives, imageTargets }) {
  useEffect(() => {
    // if (imageTargets) {
    //   XR8.XrController.configure({ imageTargets });
    // }

    if (components) {
      registerComponents(components);
    }

    if (primitives) {
      registerPrimitives(primitives);
    }

    const html = document.getElementsByTagName('html')[0];

    const origHtmlClass = html.className;

    fetch('scene.html')
      .then(function (response) {
        // The API call was successful!
        return response.text();
      })
      .then(function (htmlResponse) {
        // This is the HTML from our response as a text string
        document.body.insertAdjacentHTML('beforeend', htmlResponse);
      })
      .catch(function (err) {
        // There was an error
        console.error('Something went wrong while fetching the 3D Scene.', err);
      });

    // Cleanup

    return () => {
      const ascene = document.getElementsByTagName('a-scene')[0];

      if (ascene) {
        ascene.parentNode.removeChild(ascene);
      }
      html.className = origHtmlClass;
    };
  });

  return <></>;
}

const DISABLE_IMAGE_TARGETS = [];

export { AFrameScene, DISABLE_IMAGE_TARGETS };
