import { AFrameScene } from './aframe-components/aframe-scene';
import Header from './components/UI/Header';
import Footer from './components/UI/Footer';
import styled from 'styled-components';
import GlobalStyle from './styles/GlobalStyles';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { LanguageScreen, Screen1, Screen2, Screen3, ErrorScreen } from './Screens';
import EmptyScreen from './Screens/EmptyScreen';
import SocialShare from './components/SC/SocialShare';
import { mainComponent } from './aframe-components/main-component';
function App() {
  // Camera sharing state
  // const [showCamera, setShowCamera] = useState(false);
  // const location = useLocation();

  let markerFoundHandler = null;
  let markerLostHandler = null;
  let markerUpdatedHandler = null;
  let figureFoundHandler = null;
  let figureLostHandler = null;
  let animationFinishHandler = null;
  let isAnimation1Finished = false;
  let timeoutRunning = false;
  let timeOutValue = 3000;
  let timeout = null;

  let currentMarker = '';
  let history = useHistory();
  let currentTransform = null;
  const [languageAvailable, setLanguageAvailable] = useState(false);

  function onMarkerFound(e) {
    if (!e) return;

    if (timeoutRunning && e.detail.name === currentMarker) {
      clearTimeout(timeout);
      return;
    }

    clearTimeout(timeout);

    switch (e.detail.name) {
      case 'scene1':
        // TODO: distinguish if marker is tracked the fist time (i.e the animation starts) and the second/third time (i.e animation has ended OR animation has not ended yet)
        // TODO: When the marker gets scanned after another marker ===> start animation again. I believe this is already working tho
        history.push('/1');
        break;
      case 'scene2':
        history.push('/2');
        break;
      case 'scene3':
        history.push('/3');

        break;
      default:
        break;
    }

    localStorage.setItem('hasRoute', true);
    currentMarker = e.detail.name;
  }

  function onFigureFound(e) {}

  function onFigureLost(e) {}

  function onMarkerUpdated(e) {
    let header = document.querySelector('.header');
    // if (!header) return;
    // header.style.left = e.detail.screenCoords.x + 'px';
    // header.style.top = e.detail.screenCoords.y + 'px';
    // if (e.detail.name === "jelly" && isAnimation1Finished) {
    //   let bubbleContainer = document.querySelector("#bubble-container");
    //   let offsetX = e.detail.screenCoords.x - bubbleContainer.clientWidth / 2;
    //   let offsetY = e.detail.screenCoords.y - bubbleContainer.clientHeight;

    //   bubbleContainer.style.left = offsetX + "px";
    //   bubbleContainer.style.top = offsetY + "px";
    // }
  }

  function onMarkerLost(e) {
    if (!e) return;
    // history.push("/4");
    timeoutRunning = true;
    timeout = setTimeout(() => {
      history.push('/4');
      timeoutRunning = false;
    }, timeOutValue);
  }

  // function onAnimationFinished(e) {
  //   switch (e.detail.animationName) {
  //     case 'animation1':
  //       isAnimation1Finished = true;
  //       break;
  //     default:
  //       break;
  //   }
  // }

  function setupListeners() {
    markerFoundHandler = onMarkerFound.bind(this);
    markerUpdatedHandler = onMarkerUpdated.bind(this);
    markerLostHandler = onMarkerLost.bind(this);
    figureFoundHandler = onFigureFound.bind(this);
    figureLostHandler = onFigureLost.bind(this);
    // let a;
    // animationFinishHandler = onAnimationFinished.bind(this);
    /**
     * These Events provide you the current screen coordinates of the marker center
     */
    document.addEventListener('marker-found', markerFoundHandler);
    document.addEventListener('marker-updated', markerUpdatedHandler);
    document.addEventListener('marker-lost', markerLostHandler);
    document.addEventListener('figure-found', figureFoundHandler);
    document.addEventListener('figure-lost', figureLostHandler);

    document.addEventListener('animation-finished', animationFinishHandler);
  }

  function cleanUpListeners() {
    document.removeEventListener('marker-found', markerFoundHandler);
    document.removeEventListener('marker-updated', markerUpdatedHandler);
    document.removeEventListener('marker-lost', markerLostHandler);
    document.removeEventListener('figure-found', figureFoundHandler);
    document.removeEventListener('figure-lost', figureLostHandler);
    document.removeEventListener('animation-finished', animationFinishHandler);
  }
  useEffect(() => {
    if (localStorage.getItem('hasRoute')) {
      localStorage.setItem('hasRoute', false);
      history.push(`/?station=${window.localStorage.getItem('station')}`);
    }

    // let isLanguageSet = localStorage.getItem("language");
    if (localStorage.getItem('language') === 'null') {
      document.querySelectorAll('.language-button').forEach((el) => {
        el.addEventListener('click', () => {
          setLanguageAvailable(true);
        });
      });
    } else {
      console.log('LANGUAGE IS ALREADY SET. ENABLE XR');
      setLanguageAvailable(true);
    }

    cleanUpListeners();
    setupListeners();
  });
  useEffect(() => {
    const onxrloaded = () => {
      if (!window.XR8.XrDevice.isDeviceBrowserCompatible()) {
        history.push('/404');
      }
    };
    window.XR8 ? onxrloaded() : window.addEventListener('xrloaded', onxrloaded);
  });

  return (
    <>
      <GlobalStyle />
      <Container className='getImage'>
        <Header />
        {languageAvailable ? (
          <AFrameScene
            imageTargets={['scene1', 'scene2', 'scene3']}
            components={[{ name: 'main-component', val: mainComponent }]}
          ></AFrameScene>
        ) : (
          <></>
        )}

        <Switch>
          <Route exact path='/' component={LanguageScreen} />
          <Route exact path='/1' component={Screen1} />
          <Route exact path='/2' component={Screen2} />
          <Route exact path='/3' component={Screen3} />
          <Route exact path='/4' component={EmptyScreen} />
          <Route exact path='/404' component={ErrorScreen} />
        </Switch>

        <SocialShare />
        <canvas className='textCanvas' id='textCanvas' style={{ opacity: 1 }} />
        <Footer />
      </Container>
    </>
  );
}
export default App;

const Container = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  overflow: hidden;
  min-width: 320px;
  max-width: 414px;
  min-height: 812px;
  /* height: 100vh; */
  width: 100vw;
`;
