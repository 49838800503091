export const globalGerman = {
  headingOne: 'Das bin  @Ich ...',
  headingTwo: 'Ich wünsche @mir ...',
  headingThree: 'Meine Frage @an Dich ...',
  markerAdvice:
    'Folge mit Deinem Handy den@ Markierungen um die Ladesäule@ und lerne mich kennen.',
  footerText: 'Mehr zu mir',
  cameraTollTip: 'Teile mich unter @ #MoveToTransform',

  siemensWebsite: 'Siemens Webseite',
  privacyTitle: 'Datenschutzhinweis',
  privacyText:
    'Wir (d.h. der im Impressum genannte Betreiber dieser Webseite) nehmen den Schutz Ihrer personenbezogenen Daten ernst. Diese Datenschutzerklärung beschreibt, wie wir Ihre personenbezogenen Daten erheben und erfassen, verwenden, offenlegen, übermitteln und speichern („verarbeiten“). Die erhobenen personenbezogenen Daten sind vom Kontext Ihrer Interaktionen mit uns, den von uns bezogenen Produkten, Dienstleistungen und Features, Ihrem Standort und Sitz sowie dem jeweils geltenden Recht abhängig.',
  privacyButton: 'Weiterlesen',
  imprintTitle: 'Impressum',
  imprintText:
    'Konzernzentrale @Siemens Aktiengesellschaft @Werner-von-Siemens-Straße 1 @80333 München @Deutschland',
  imprintContact: {
    email: 'contact@siemens.com',
    tel: 'Tel. +49 89 636-00',
    fax: 'Fax +49 69 6682-6664',
  },
  imprintButton: 'Weiterlesen',
  questionCameraText: 'Teile Deine Meinung @auf Social Media.',
};
export const de = [
  {
    id: 0,
    link: 'mover-alex-bangula',
    bubbleTextOne: 'Hi, ich bin Alex Bangula.',
    bubbleTextTwo:
      "Auf meinem YouTube-Channel erzähle ich euch von <span style='background-color: #00FFB9'>meinen Erfahr&shy;ungen &#8203;mit <span style='white-space: nowrap'>E-Mobilität.</span></span>",
    bubbleTextThree:
      "Deshalb bin ich ein <span style='background-color: #00FFB9'>Mobility Mover.</span>",
    wishTextOne:
      ' <span style="background-color: #00FFB9">Eine faktenbasierte</span> Mobilitätsdebatte.',
    // ' <span style="background-color: #00FFB9">Fakten&shy;basierte Mobilitätsdebatte</span> statt blinder Leiden&shy;schaft.',

    wishTextTwo:
      'Ein gut <span style="background-color: #00FFB9">ausgebautes, funktionierendes und leicht zugängliches Ladenetz,</span> das so komfortabel ist, als würde ich mein Auto tanken.',
    // 'Ladenetz mit gleich&shy;em <span style="background-color: #00FFB9">Komfort wie beim Tanken.</span>',
    questionTextOne:
      'Klar ist wichtig, wo Rohstoffe und Strom für &#8203; <span style="white-space: nowrap">E-Autos</span> herkommen - <span style="background-color: #00FFB9">aber wo kommt &#8203;eigentlich &#8203;unser Benzin her?</span> ',
  },
  {
    id: 1,
    link: 'mover-nari-kahle',
    bubbleTextOne: 'Hallo, ich bin Nari Kahle.',
    bubbleTextTwo:
      'In meinem neuen Buch „Mobilität in Bewegung“ fordere ich, dass die <span style="background-color: #00FFB9">Mobilitätswende unbedingt auch sozial gedacht werden muss</span>.',
    // 'In meinem Buch fordere ich, dass <span style="background-color: #00FFB9">neue Mobilität un&shy;bedingt auch sozial sein muss</span>.',
    bubbleTextThree:
      'Deshalb bin ich ein <span style="background-color: #00FFB9">Mobility Mover</span>.',
    wishTextOne:
      'Dass wir anderen nicht vor&shy;schreiben, <span style="background-color: #00FFB9">welcher Mobilitäts&shy;weg der Richtige ist</span>.',
    wishTextTwo:
      'Dass wir die&shy;jenigen im Blick behalten, <span style="background-color: #00FFB9">die Mobilität am meisten benöti&shy;gen</span>.',
    questionTextOne:
      '<span style="background-color: #00FFB9">Wie kann Mobilität besser werden</span> – zugänglicher, sozialer und gleich&shy;zeitig wirt&shy;schaft&shy;lich sein?',
  },
  {
    id: 2,
    link: 'mover-giovane-elber',
    bubbleTextOne: 'Hi, ich bin Giovane Élber.',
    bubbleTextTwo:
      'Als Botschafter des FC Bayern München will ich, dass der <span style="background-color: #00FFB9">Verein auch in Sachen Nach&shy;haltig&shy;keit die Spitze anstrebt</span>.',
    bubbleTextThree:
      'Deshalb bin ich ein <span style="background-color: #00FFB9">Mobility Mover</span>.',
    headingTwo: 'Ich wünsche @mir ...',
    wishTextOne:
      'Dass die <span style="background-color: #00FFB9">Menschen sich auch in Zukunft mit dem nötigen Respekt begegnen</span> – egal woher man kommt.',
    wishTextTwo:
      'Dass im Jahr 2030 ein <span style="background-color: #00FFB9">Stadionbesuch mit Aufladen des E-Autos im Parkhaus</span> möglich ist.',
    questionTextOne:
      'Möchtest du in einer Welt leben, in der es in <span style="background-color: #00FFB9">vielen Ländern zu heiß wird, um Fußball zu spielen?</span>',
  },
  {
    id: 3,
    link: 'mover-ignacio-diaz',
    bubbleTextOne: 'Hey, ich bin Ignacio Diaz.',
    bubbleTextTwo:
      'Ich habe für Euch die <span style="background-color: #00FFB9">IAA-Ladeinfra&shy;struktur für &#8203;E-Autos</span> aufgebaut.',
    bubbleTextThree:
      'Deshalb bin ich ein <span style="background-color: #00FFB9">Mobility Mover</span>.',
    wishTextOne:
      'Dass die Zurück&shy;haltung, <span style="background-color: #00FFB9">auf E-Ant&shy;riebe um&shy;zu&shy;stellen,</span> über&shy;wunden wird. ',
    wishTextTwo:
      '<span style="background-color: #00FFB9">Mehr E-Fahr&shy;zeuge &#8203;und Lademöglich&shy;keiten</span> &#8203;– &#8203;idealer&shy;weise von Siemens.',
    questionTextOne:
      'Würdest du gerne in einer Welt leben, wo dein <span style="background-color: #00FFB9">"Sprit" aus lokalen Ressourcen – als Dein Ökostrom – entsteht?</span>',
  },
  {
    id: 4,
    link: 'mover-raul-krauthausen',
    bubbleTextOne: 'Hallo, ich bin Raul &#8203;Kraut&shy;hausen.',
    bubbleTextTwo:
      'Ich unter&shy;stütze Siemens dabei, <span style="background-color: #00FFB9">inklus&shy;ive &#8203;E-Ladesäulen</span> zu entwick&shy;eln.',
    bubbleTextThree:
      'Deshalb bin ich ein <span style="background-color: #00FFB9">Mobility Mover</span>.',
    wishTextOne:
      '<span style="background-color: #00FFB9">Barrierefreiheit als  &#8203;Menschenrecht</span> und Qualitätskriterium  &#8203;für &#8203; Mobilitäts&shy;planung.',
    wishTextTwo:
      'Spontanes, <span style="background-color: #00FFB9">inter&shy;modales Reisen als Möglich&shy;keit für alle</span> Menschen.',
    questionTextOne:
      'Jeder zehnte Mensch hat eine Behind&shy;erung, <span style="background-color: #00FFB9">aber wie viele kennst Du persön&shy;lich?</span>',
  },
  {
    id: 5,
    link: 'mover-svenja-weiss',
    bubbleTextOne: 'Hi, ich bin Svenja Weiß.',
    bubbleTextTwo:
      'Mit "Mobility as a &#8203;Service" bekommt man <span style="background-color: #00FFB9">Zu&shy;gang zu der Mobilität, die man gerade braucht</span>.',
    bubbleTextThree:
      'Deshalb bin ich ein <span style="background-color: #00FFB9">Mobility Mover</span>.',
    wishTextOne:
      'Dass die <span style="background-color: #00FFB9">ÖV-Nutzung „hipper“</span> wird. ',
    wishTextTwo:
      'Ein Be&shy;wusst&shy;sein dafür, welches <span style="background-color: #00FFB9">Plus an Lebens&shy;qualität</span> und Frei&shy;heit die <span style="background-color: #00FFB9">flexi&shy;ble Nut&shy;zung verschiedenster Verkehrs&shy;angebote bietet</span>. ',
    questionTextOne:
      'Unter welchen Beding&shy;ungen kannst du dir <span style="background-color: #00FFB9">ein Leben ohne Auto vorstellen?</span>',
  },
  {
    id: 6,
    link: 'mover-zhang-xi',
    bubbleTextOne: 'Hallo, ich bin Xi Zhang.',
    bubbleTextTwo:
      'Meine Aufgabe ist es, den <span style="background-color: #00FFB9">Aufbau der Lade&shy;infra&shy;struktur zu be&shy;schleuni&shy;gen</span>.',
    bubbleTextThree:
      'Deshalb bin ich ein <span style="background-color: #00FFB9">Mobility Mover</span>.',
    wishTextOne:
      '<span style="background-color: #00FFB9">Saubere Luft zum Atmen</span>, auch wenn ich mit dem Fahr&shy;rad auf dem Mittleren Ring in München unter&shy;wegs bin. ',
    wishTextTwo:
      'Dass sich immer <span style="background-color: #00FFB9">mehr Menschen dem Kampf gegen den Klima&shy;wandel an&shy;schlie&shy;ßen</span>, denn jede Aktion zählt. ',
    questionTextOne:
      'Könnt Ihr euch eine Zukunft vor&shy;stellen, in der es <span style="background-color: #00FFB9">keinen Unter&shy;schied mehr zwischen Olymp&shy;ischen Winter&shy;spielen und Olymp&shy;ischen Sommer&shy;spielen gibt?</span>',
  },
  {
    id: 7,
    link: 'mover-anita-mathieu',
    bubbleTextOne: 'Hallo, ich bin Anita Mathieu.',
    bubbleTextTwo:
      'Ich arbeite an  &#8203;<span style="background-color: #00FFB9">intelli&shy;genten  &#8203;Verkehrs&shy;manage&shy;ment&shy;system&shy;en</span> für eine nach&shy;haltige Mobilität.  ',
    bubbleTextThree:
      'Deshalb bin ich ein <span style="background-color: #00FFB9">Mobility Mover</span>',
    wishTextOne:
      'Dass jeder <span style="background-color: #00FFB9">Climate Action als höchste Priorität</span> sieht.',
    wishTextTwo:
      'Dass es mehr Visibilität und Anreize für <span style="background-color: #00FFB9">um&shy;welt&shy;orientiertes Verkehrs&shy;management</span> gibt.',
    questionTextOne:
      'Wenn du wüsstest, dass du in einem Jahr von einer Natur&shy;katastrophe aufgrund des Klima&shy;wandels heim&shy;gesucht wirst - <span style="background-color: #00FFB9">was würdest du heute ändern, insbe&shy;sondere in Bezug auf dein Mobilitäts&shy;verhalten?</span>',
  },
];
