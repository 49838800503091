import { gsap, Power2, Power3 } from 'gsap/all';

const AFRAME = window.AFRAME;
const THREE = window.THREE;
const XR8 = window.XR8;

const questionMarkObjects = [];
const heartObjects = [];
const bubbleObjects = [];
const scaleFactor = 0.001;
const NUM_QUESTIONSMARKS = 28;
const NUM_BUBBLES = 28;
const NUM_HEARTS = 28;

export const mainComponent = {
  init: function () {
    // First of all get the current station ID to identify the marker for screen1
    this.currentStation = window.localStorage.getItem('station');

    let sceneOneImageTargetName = 'scene1-' + this.currentStation;

    let imageTargets = [sceneOneImageTargetName, 'scene2', 'scene3'];
    if (imageTargets) {
      window.XR8.XrController.configure({ imageTargets });
    }

    this.currentMarkerName = '';
    this.currentSelectedScreen = null;
    this.currentScreenPosition = new THREE.Vector3();
    this.animationOfCurrentScreenStarted = false;
    this.camera = document.querySelector('[camera]').getObject3D('camera');
    this.offset = new THREE.Vector3(0, 4, 0);
    this.frustum = new THREE.Frustum();
    this.figureFound = false;
    this.origin = new THREE.Vector3();
    this.currentLoadedModels = 0;
    this.isFullyLoaded = false;
    this.animations = [];

    document.addEventListener('xrimagefound', this.onMarkerFound.bind(this));
    document.addEventListener('xrimagelost', this.onMarkerLost.bind(this));
    document.addEventListener('xrimageupdated', this.onMarkerUpdate.bind(this));
    // document.addEventListener("click", this.onClick.bind(this));
    // this.animate();
    // this.tick = AFRAME.utils.throttleTick(this.tick, 50, this);

    this.scene1Container = document.querySelector('#scene1');
    this.scene2Container = document.querySelector('#scene2');
    this.scene3Container = document.querySelector('#scene3');

    for (let i = 1; i <= NUM_BUBBLES; i++) {
      let bubble = document.createElement('a-entity');
      bubble.id = 'bubble-' + i;
      bubble.setAttribute('gltf-model', '#bubble-model');
      this.scene1Container.appendChild(bubble);
    }

    for (let i = 1; i <= NUM_HEARTS; i++) {
      let heart = document.createElement('a-entity');
      heart.id = 'heart-' + i;
      heart.setAttribute('gltf-model', '#heart-model');
      this.scene2Container.appendChild(heart);
    }

    for (let i = 1; i <= NUM_HEARTS; i++) {
      let questionMark = document.createElement('a-entity');
      questionMark.id = 'question-mark-' + i;
      questionMark.setAttribute('gltf-model', '#question-mark-model');
      this.scene3Container.appendChild(questionMark);
    }
  },

  // tick() {
  //   if (!this.camera || !this.currentSelectedScreen) return;
  //   // this.camera.matrixAutoUpdate = false;
  //   // this.camera.updateMatrix();
  //   // this.camera.updateMatrixWorld();

  //   // this.camera.updateProjectionMatrix();
  //   this.frustum.setFromProjectionMatrix(
  //     new THREE.Matrix4().multiplyMatrices(
  //       this.camera.projectionMatrix,
  //       this.camera.matrixWorldInverse
  //     )
  //   );

  //   if (
  //     this.frustum.containsPoint(this.currentSelectedScreen.object3D.position)
  //   ) {
  //     if (!this.figureFound) {
  //       document.dispatchEvent(
  //         new CustomEvent("figure-found", {
  //           detail: {
  //             name: this.currentMarkerName,
  //           },
  //         })
  //       );
  //       this.figureFound = true;
  //     }
  //   } else {
  //     if (this.figureFound) {
  //       document.dispatchEvent(
  //         new CustomEvent("figure-lost", {
  //           detail: {
  //             name: this.currentMarkerName,
  //           },
  //         })
  //       );

  //       this.figureFound = false;
  //     }
  //   }
  // },
  onMarkerFound: function (e) {
    // if (!localStorage.getItem("status")) return;

    // FIRE THE EVENT FOR THE REACT SIDE
    let eventName = '';
    if (e.detail.name.includes('scene1')) {
      eventName = 'scene1';
    } else {
      eventName = e.detail.name;
    }

    document.dispatchEvent(
      new CustomEvent('marker-found', {
        detail: {
          name: eventName,
          screenCoords: this.transformWorldToScreen(e.detail.position),
        },
      })
    );
    // END FIRE EVENT FOR THE REACT SIDE

    // TEST IF THE MARKER IS A NEW ONE, IF NOT - RETURN
    if (
      (this.currentMarkerName.includes('scene1') && e.detail.name.includes('scene1')) ||
      this.currentMarkerName === e.detail.name
    ) {
      return;
    }

    // MARKER IS NEW - CONTINUE
    if (e.detail.name.includes('scene1')) {
      this.currentMarkerName = 'scene1';
    } else {
      this.currentMarkerName = e.detail.name;
    }

    this.figureFound = false;

    this.animationOfCurrentScreenStarted = false;

    if (this.currentSelectedScreen) {
      this.currentSelectedScreen.setAttribute('visible', false);
    }

    this.currentSelectedScreen = document.querySelector('#' + this.currentMarkerName);
    this.currentSelectedScreen.setAttribute('visible', true);
    this.currentSelectedScreen.object3D.position.copy(e.detail.position);
    this.currentSelectedScreen.object3D.quaternion.copy(e.detail.rotation);
    this.currentSelectedScreen.object3D.scale.set(
      e.detail.scale,
      e.detail.scale,
      e.detail.scale
    );

    // this.currentSelectedScreen.object3D.position.add(this.offset);

    // document.querySelector('#question_mark').addEventListener('model-loaded', (e) => {
    //   console.log(e);
    // })
    // kill the entire animation:

    if (this.t1) {
      this.t1.kill();

      // set to null so the reference is removed
      this.t1 = null;
    }

    switch (this.currentMarkerName) {
      case 'scene1':
        if (!this.animationOfCurrentScreenStarted) {
          this.animationOfCurrentScreenStarted = true;
          this.initScene1();
          this.animateScene1();
        }
        break;
      case 'scene2':
        if (!this.animationOfCurrentScreenStarted) {
          this.animationOfCurrentScreenStarted = true;
          this.initScene2();
          this.animateScene2();
        }
        break;
      case 'scene3':
        if (!this.animationOfCurrentScreenStarted) {
          this.animationOfCurrentScreenStarted = true;
          this.initScene3();
          this.animateScene3();
        }
        break;
      case 'scene4':
        break;
      default:
        break;
    }
    // console.log("##########marker found ", e);
  },

  pauseAllAnimations() {
    this.animations.forEach((el) => {
      el.pause();
    });
  },

  onMarkerLost: function (e) {
    document.dispatchEvent(new CustomEvent('marker-lost'));
    console.log('marker Lost ', e);
  },

  onMarkerUpdate: function (e) {
    if (
      (this.currentMarkerName.includes('scene1') && !e.detail.name.includes('scene1')) ||
      (!this.currentMarkerName.includes('scene1') &&
        this.currentMarkerName !== e.detail.name)
    ) {
      return;
    }
    if (e.detail.name.includes('scene1')) {
      this.currentMarkerName = 'scene1';
    } else {
      this.currentMarkerName = e.detail.name;
    }
    this.currentSelectedScreen.object3D.quaternion.copy(e.detail.rotation);
    this.currentSelectedScreen.object3D.position.copy(e.detail.position);
    // this.currentSelectedScreen.object3D.position.add(this.offset);
    this.currentSelectedScreen.object3D.scale.set(
      e.detail.scale,
      e.detail.scale,
      e.detail.scale
    );
    this.currentSelectedScreen.object3D.visible = true;

    // document.dispatchEvent(
    //   new CustomEvent("marker-updated", {
    //     detail: {
    //       name: e.detail.name,
    //       screenCoords: this.transformWorldToScreen(e.detail.position),
    //     },
    //   })
    // );
    // console.log("marker update ", e);
  },

  destroy: function () {
    // console.log('Destroy Main Component ');
  },

  transformWorldToScreen(point) {
    let windowWidth = window.innerWidth,
      windowHeight = window.innerHeight;
    let widthHalf = windowWidth / 2,
      heightHalf = windowHeight / 2;
    // this.el.object3D.getWorldPosition(this.currentScreenPosition);
    this.currentScreenPosition.copy(point);
    this.currentScreenPosition.project(this.camera);
    this.currentScreenPosition.x = this.currentScreenPosition.x * widthHalf + widthHalf;
    this.currentScreenPosition.y =
      -(this.currentScreenPosition.y * heightHalf) + heightHalf;
    return this.currentScreenPosition;
  },

  initScene1: function () {
    let objectsCount = this.scene1Container.children.length;

    const rotation = new window.THREE.Vector3(0.2, 0.4, 0.0);

    for (let i = 0; i < this.scene1Container.children.length; i++) {
      bubbleObjects[i] = {
        id: i,
        object3D: document.querySelector('#bubble-' + (i + 1)).object3D,
        material: null,
        set visible(value) {
          this.object3D.visible = value;
        },
        get visible() {
          return this.object3D.visible;
        },
        set opacity(value) {
          this.material.opacity = value;
        },
        get opacity() {
          return this.material.opacity;
        },
        set scale(value) {
          this.object3D.scale.set(value, value, value);
        },
        get scale() {
          return this.object3D.scale.x;
        },
        set xCoordinate(value) {
          this.object3D.position.set(
            value,
            this.object3D.position.y,
            this.object3D.position.z
          );
        },
        get xCoordinate() {
          return this.object3D.position.x;
        },
        set yCoordinate(value) {
          this.object3D.position.set(
            this.object3D.position.x,
            value,
            this.object3D.position.z
          );
        },
        get yCoordinate() {
          return this.object3D.position.y;
        },
        set yAngle(value) {
          this.object3D.rotation.set(
            this.object3D.rotation.x,
            value,
            this.object3D.rotation.z
          );
        },
        get yAngle() {
          return this.object3D.rotation.y;
        },
      };
    }

    for (let i = 0; i < objectsCount; i++) {
      bubbleObjects[i].object3D.traverse(function (node) {
        if (node.isMesh) {
          bubbleObjects[i].material = node.material;
        }
      });
    }

    for (let i = 0; i < objectsCount; i++) {
      bubbleObjects[i].visible = true;
      bubbleObjects[i].object3D.rotation.set(rotation.x, rotation.y, rotation.z);
      // let position = positions[i];
      // heartObjects[i].object3D.position.set(position.x, position.y, position.z);
      bubbleObjects[i].scale = scaleFactor;
      bubbleObjects[i].material.opacity = 1;
      bubbleObjects[i].material.transparent = true;
    }
  },

  animateScene1: function () {
    this.t1 = gsap.timeline({ delay: 0 });

    bubbleObjects.forEach((bubble) => {
      this.t1
        .fromTo(
          bubble,
          {
            xCoordinate: gsap.utils.random(-0.5, 0.5),
            yCoordinate: gsap.utils.random(-1.1, 0),
            opacity: 1,
            visible: true,
            scale: function (i) {
              return scaleFactor * gsap.utils.random(0.5, 4);
            },
          },
          {
            xCoordinate: gsap.utils.random(-4, 4),
            duration: gsap.utils.random(5.5, 7),
            // delay: function (i) {
            //   return 0.001 * i;
            // },
            delay: 0,
            scale: scaleFactor * gsap.utils.random(6, 9),
            yCoordinate: gsap.utils.random(4.5, 7),
            repeat: -1,
            opacity: 1,
            repeatRefresh: true,
          }
        )
        .progress(Math.random());
    });
  },

  initScene2: function () {
    const rotation = new window.THREE.Vector3(0.2, 0.4, 0.0);

    for (let i = 0; i < NUM_HEARTS; i++) {
      heartObjects[i] = {
        id: i,
        object3D: document.querySelector('#heart-' + (i + 1)).object3D,
        material: null,
        set visible(value) {
          this.object3D.visible = value;
        },
        get visible() {
          return this.object3D.visible;
        },
        set opacity(value) {
          this.material.opacity = value;
        },
        get opacity() {
          return this.material.opacity;
        },
        set scale(value) {
          this.object3D.scale.set(value, value, value);
        },
        get scale() {
          return this.object3D.scale.x;
        },
        set xCoordinate(value) {
          this.object3D.position.set(
            value,
            this.object3D.position.y,
            this.object3D.position.z
          );
        },
        get xCoordinate() {
          return this.object3D.position.x;
        },
        set yCoordinate(value) {
          this.object3D.position.set(
            this.object3D.position.x,
            value,
            this.object3D.position.z
          );
        },
        get yCoordinate() {
          return this.object3D.position.y;
        },
        set yAngle(value) {
          this.object3D.rotation.set(
            this.object3D.rotation.x,
            value,
            this.object3D.rotation.z
          );
        },
        get yAngle() {
          return this.object3D.rotation.y;
        },
      };
    }

    for (let i = 0; i < NUM_HEARTS; i++) {
      heartObjects[i].object3D.traverse(function (node) {
        if (node.isMesh) {
          heartObjects[i].material = node.material;
        }
      });
    }

    for (let i = 0; i < NUM_HEARTS; i++) {
      heartObjects[i].visible = true;
      heartObjects[i].object3D.rotation.set(rotation.x, rotation.y, rotation.z);
      // let position = positions[i];
      // heartObjects[i].object3D.position.set(position.x, position.y, position.z);
      heartObjects[i].scale = scaleFactor;
      heartObjects[i].material.opacity = 1;
      heartObjects[i].material.transparent = true;
    }
  },

  animateScene2: function () {
    this.t1 = gsap.timeline({ delay: 0 });

    heartObjects.forEach((heart) => {
      this.t1
        .fromTo(
          heart,
          {
            xCoordinate: gsap.utils.random(-0.5, 0.5),
            yCoordinate: gsap.utils.random(-1.1, 0),
            opacity: 1,
            visible: true,
            scale: function (i) {
              return scaleFactor * gsap.utils.random(0.5, 4);
            },
          },
          {
            xCoordinate: gsap.utils.random(-4, 4),
            duration: gsap.utils.random(5.5, 7),
            // delay: function (i) {
            //   return 0.001 * i;
            // },
            delay: 0,
            scale: scaleFactor * gsap.utils.random(6, 9),
            yCoordinate: gsap.utils.random(4.5, 7),
            repeat: -1,
            opacity: 1,
            repeatRefresh: true,
          }
        )
        .progress(Math.random());
    });
  },

  initScene3: function () {
    const rotation = new window.THREE.Vector3(0.2, 0.4, 0.0);

    for (let i = 0; i < NUM_QUESTIONSMARKS; i++) {
      questionMarkObjects[i] = {
        id: i,
        object3D: document.querySelector('#question-mark-' + (i + 1)).object3D,
        material: null,
        set visible(value) {
          this.object3D.visible = value;
        },
        get visible() {
          return this.object3D.visible;
        },
        set opacity(value) {
          this.material.opacity = value;
        },
        get opacity() {
          return this.material.opacity;
        },
        set scale(value) {
          this.object3D.scale.set(value, value, value);
        },
        get scale() {
          return this.object3D.scale.x;
        },
        set xCoordinate(value) {
          this.object3D.position.set(
            value,
            this.object3D.position.y,
            this.object3D.position.z
          );
        },
        get xCoordinate() {
          return this.object3D.position.x;
        },
        set yCoordinate(value) {
          this.object3D.position.set(
            this.object3D.position.x,
            value,
            this.object3D.position.z
          );
        },
        get yCoordinate() {
          return this.object3D.position.y;
        },
        set yAngle(value) {
          this.object3D.rotation.set(
            this.object3D.rotation.x,
            value,
            this.object3D.rotation.z
          );
        },
        get yAngle() {
          return this.object3D.rotation.y;
        },
      };
    }

    for (let i = 0; i < NUM_QUESTIONSMARKS; i++) {
      questionMarkObjects[i].object3D.traverse(function (node) {
        if (node.isMesh) {
          questionMarkObjects[i].material = node.material;
        }
      });
    }

    for (let i = 0; i < NUM_QUESTIONSMARKS; i++) {
      questionMarkObjects[i].visible = true;
      questionMarkObjects[i].object3D.rotation.set(rotation.x, rotation.y, rotation.z);
      // let position = positions[i];
      // heartObjects[i].object3D.position.set(position.x, position.y, position.z);
      questionMarkObjects[i].scale = scaleFactor;
      questionMarkObjects[i].material.opacity = 1;
      questionMarkObjects[i].material.transparent = true;
    }
  },

  animateScene3: function () {
    this.t1 = gsap.timeline({ delay: 0 });

    questionMarkObjects.forEach((questionMark) => {
      this.t1
        .fromTo(
          questionMark,
          {
            xCoordinate: gsap.utils.random(-0.5, 0.5),
            yCoordinate: gsap.utils.random(-1.1, 0),
            opacity: 1,
            visible: true,
            scale: function (i) {
              return scaleFactor * gsap.utils.random(0.5, 4);
            },
          },
          {
            xCoordinate: gsap.utils.random(-4, 4),
            duration: gsap.utils.random(5.5, 7),
            // delay: function (i) {
            //   return 0.001 * i;
            // },
            delay: 0,
            scale: scaleFactor * gsap.utils.random(6, 9),
            yCoordinate: gsap.utils.random(4.5, 7),
            repeat: -1,
            opacity: 1,
            repeatRefresh: true,
          }
        )
        .progress(Math.random());
    });
  },

  on3DAnimationFinished() {},
};
