import React, { createContext, useReducer, useContext } from 'react';

//Define Context
const GlobalStateContext = createContext();
const GlobalDispatchContext = createContext();

//Reducer
const globalReducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_LANGUAGE': {
      return {
        ...state,
        currentLanguage: action.language,
      };
    }
    case 'TOGGLE_STATION': {
      return {
        ...state,
        currentStation: action.station,
      };
    }
    case 'TOGGLE_LANGUAGE_STATUS': {
      return {
        ...state,
        languageStatus: action.languageStatus,
      };
    }
    case 'TOGGLE_CAMERA': {
      return {
        ...state,
        showCamera: action.showCamera,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

//Provider
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(globalReducer, {
    currentLanguage:
      window.localStorage.getItem('language') === null
        ? null
        : window.localStorage.getItem('language'),
    showCamera: false,
    languageStatus:
      window.localStorage.getItem('status') === null
        ? false
        : window.localStorage.getItem('status'),
    currentStation:
      window.localStorage.getItem('station') === null ||
      window.localStorage.getItem('station') === 'undefined'
        ? '8'
        : window.localStorage.getItem('station'),
  });

  return (
    <GlobalDispatchContext.Provider value={dispatch}>
      <GlobalStateContext.Provider value={state}>{children}</GlobalStateContext.Provider>
    </GlobalDispatchContext.Provider>
  );
};

//custom hooks for when we want to use our global state
export const useGlobalStateContext = () => useContext(GlobalStateContext);

export const useGlobalDispatchContext = () => useContext(GlobalDispatchContext);
