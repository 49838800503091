import styled from 'styled-components';

export const FooterContainer = styled.div`
  position: fixed;
  bottom: 2%;
  background-color: var(--primary);
  display: flex;
  z-index: 200;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 90vw;

  .footer-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5.6rem;
    padding: 1rem 2rem;
    width: 100%;
    @media screen and (max-height: 654px) {
      width: 100%;
    }
    a {
      color: var(--secondary);
      border: var(--secondaryBorder);
      padding: 0.6rem 2rem;
      text-decoration: none;
      cursor: pointer;
      font-family: 'SiemensSansProfBlack', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

      font-size: clamp(1.2rem, 2rem, 2.2rem);

      @media screen and (max-height: 568px) {
        font-size: clamp(1.2rem, 1.6rem, 1.8rem);
      }
      @media screen and (max-width: 310px) {
        font-size: clamp(1.2rem, 1.4rem, 1.8rem);
      }
      &:hover {
        background: var(--secondary);
        color: var(--primary);
      }
    }
    .footer-image {
      cursor: pointer;
      padding: 1rem 0.6rem;
      z-index: 1000;
    }
  }
`;

export const CameraToolTip = styled.div`
  position: absolute;
  right: -1%;
  pointer-events: none;
  bottom: 60%;
  z-index: 5; /* opacity: 0; */
  @media screen and (max-width: 360px) {
    right: -2%;
  }
  @media screen and (max-height: 568px) {
    right: 3%;
    bottom: 40%;
  }
  /* width: 40%; */
  .bubble-content {
    min-height: 10.8rem;
    pointer-events: none;

    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    height: 100%;
    position: relative;

    &__heading {
      text-align: center;
      z-index: 10;

      /* top: -0.5rem; */
      padding: 0.8rem 1.2rem;
      position: relative;
      top: -0.5rem;
      .title {
        opacity: 0;
        font-size: clamp(1.2rem, 1.6rem, 1.8rem);
        @media screen and (max-height: 568px) {
          font-size: clamp(1rem, 1.3rem, 1.1rem);
        }
        font-weight: 700;
        line-height: 2rem;
        &-1 {
          opacity: 0;
        }
      }
    }
    &-footer {
      background: url('/ui/svg/common/camera-tip.svg') no-repeat center content-box;
      background-size: contain;
      overflow: visible;
    }
  }
`;
