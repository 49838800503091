// Menu Styles

import styled from 'styled-components';

export const MenuContainer = styled.div`
  background: var(--primary);
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  position: fixed;
  width: 42.4rem;
  padding: 1.8rem 1rem;
  @media screen and (max-height: 568px) {
    padding: 0.5rem 1.5rem;
  }
  z-index: 20;
  top: 10%;
  transform: translate(0, -150%);
  z-index: 200;
  .menu-item {
    flex: 1;
    padding: 1rem;
    @media screen and (max-height: 568px) {
      padding: 0.5rem 1rem;
    }
    display: flex;
    text-decoration: none;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    cursor: pointer;
    & > * {
      pointer-events: none;
    }
    &:hover {
      background: #002949;
    }
    &-title {
      color: var(--white);
      font-size: clamp(1rem, 1.8rem, 1.7rem);
      @media screen and (max-height: 568px) {
        font-size: clamp(1rem, 1.4rem, 1.7rem);
      }
    }
    .select-language {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto 0 2rem;
      & > * {
        padding: 0.5rem;
      }
    }
    .internet-icon {
      margin-right: 1rem;
    }
  }
`;

export const LanguageItem = styled.div`
  flex: 1;
  padding: 1rem;
  display: flex;
  text-decoration: none;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  /* & > * {
    pointer-events: none;
  } */
  &:hover {
    background: #002949;
  }
  &-title {
    color: var(--white);
    font-size: 1.8rem;
  }
  .select-language {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto 0 2rem;
    & > * {
      padding: 0.5rem;
    }
  }
  .internet-icon {
    margin-right: 1rem;
  }
`;
