import React, { useEffect, useState } from 'react';
import { gsap } from 'gsap/all';
import {
  useGlobalDispatchContext,
  useGlobalStateContext,
} from '../../../utils/globalContext';
import { CameraContainer, DownloadImage } from './styles';
import { isIOS } from 'mobile-device-detect';

function SocialShare() {
  const t1 = gsap.timeline({ delay: 1 });
  const dispatch = useGlobalDispatchContext();
  const { showCamera, currentLanguage, currentStation } = useGlobalStateContext();
  const [imageFile, setImageFile] = useState(null);
  // Original Image URL
  const imageURLOrginal =
    currentLanguage === 'english'
      ? `/img/station${currentStation}_english.jpg`
      : `/img/station${currentStation}_german.jpg`;
  // Download Image URL
  const downloadURLOrginal =
    currentLanguage === 'english'
      ? `/download/station${currentStation}_english.png`
      : `/download/station${currentStation}_german.png`;
  const cameraHandler = () => {
    if (showCamera === false) {
      dispatch({ type: 'TOGGLE_CAMERA', showCamera: true });
    } else {
      dispatch({ type: 'TOGGLE_CAMERA', showCamera: false });
    }
  };

  // const downloadScreenshot = () => takeScreenShot(doc).then(download);
  const downloadScreenshot = () => {
    const XR8 = window.XR8;
    XR8.addCameraPipelineModule(XR8.canvasScreenshot().cameraPipelineModule());
    const myOtherCanvas = document.querySelector('.topImage');
    XR8.CanvasScreenshot.setForegroundCanvas(myOtherCanvas);
    XR8.CanvasScreenshot.configure({ maxDimension: 1080, jpgCompression: 80 });
    XR8.canvasScreenshot()
      .takeScreenshot()
      .then(
        async (data) => {
          // myImage is an <img> HTML element

          const dataBase64 = 'data:image/jpeg;base64,' + data;
          const response = await fetch(dataBase64);
          const blob = await response.blob();
          const file = new File([blob], downloadURLOrginal, { type: blob.type });
          if (file) setImageFile(file);
          gsap.set('.share-foto-image-1', {
            attr: { src: dataBase64 },
          });

          const imageURL = URL.createObjectURL(blob);

          const link = document.querySelector('.download-camera-image');
          link.href = imageURL;
          link.textContent = 'Save ';
          link.download = `moveToTransform_${currentStation}.jpg`;
        },
        (error) => {
          // Handle screenshot error.
        }
      );
    const t1 = gsap.timeline({ delay: 0 });
    t1.to('.share-foto-image-2', {
      display: 'none',
      duration: 0.001,
    })
      .to('.share-button-text', {
        display: 'none',
        duration: 0.001,
      })

      .to('.share-photo-action', {
        display: 'flex',
        duration: 0.001,
      })

      .to('.share-foto-image-1', {
        // width: 'clamp(100%, 100%, 100%) ',
        height: '100%',
        duration: 0.001,
        // ease: Power2.easeIn,
      });
  };
  const handleCancelCamera = () => {
    gsap.to('.camera-container', {
      duration: 0.5,
      y: '80vh',
      delay: 0.5,
    });

    setTimeout(() => {
      gsap.to(
        '.share-button-action',
        {
          display: 'none',
          duration: 0.001,
        },
        '-=1'
      );
      gsap.to('.share-photo-action', {
        display: 'none',
        duration: 0.001,
      });
      gsap.set('.share-foto-image-1', {
        attr: { src: '/img/foto-camera.png' },
      });
      gsap.to(
        '.share-foto-image-1',
        {
          display: 'flex',
          duration: 0.001,
        },
        '-=1'
      );
      gsap.to('.share-foto-image-2', {
        display: 'flex',
        duration: 0.001,
      });
      gsap.to(
        '.share-button-text',
        {
          display: 'flex',
          duration: 0.001,
        },
        '-=1'
      );
      gsap.to('.share-foto-image-2', {
        width: ' clamp(50%,50%,50%)',
        height: 'clamp(12rem, 16rem, 17rem)',
        duration: 0.001,
      });
      cameraHandler();
    }, 500);
  };
  const handleChoosePhoto = () => {
    const t1 = gsap.timeline({ delay: 0 });
    t1.to('.share-foto-image-1', {
      display: 'none',
      duration: 0.001,
    })
      .to('.share-button-text', {
        display: 'none',
        duration: 0.001,
      })

      .to('.share-button-action', {
        display: 'flex',
        duration: 0.001,
      })
      .to('.share-foto-image-2', {
        width: 'clamp(100%, 100%, 100%) ',
        height: '100%',
        duration: 0.001,
        // ease: Power2.easeIn,
      });
  };
  const handleShareButton = async () => {
    // Check if navigator.share is supported by the browser
    if (navigator.share) {
      const response = await fetch(imageURLOrginal);
      const blob = await response.blob();
      const file = new File([blob], imageURLOrginal, { type: blob.type });
      navigator
        .share({
          title: '#MoveToTransform',
          url: imageURLOrginal,
          text: 'Siemens',
          files: [file],
        })
        .then(() => {
          console.log('Sharing successfull');
        })
        .catch(() => {
          console.log('Sharing failed');
        });
    } else {
      console.log('Sorry! Your browser does not support Web Share API');
    }
  };
  const handlePhotoShareButton = async () => {
    // Check if navigator.share is supported by the browser
    if (navigator.share) {
      navigator
        .share({
          title: '#MoveToTransform',
          text: 'Siemens',
          files: [imageFile],
        })
        .then(() => {
          console.log('Sharing successfull');
        })
        .catch(() => {
          console.log('Sharing failed');
        });
    } else {
      console.log('Sorry! Your browser does not support Web Share API');
    }
  };
  useEffect(() => {
    if (showCamera) {
      t1.to('.camera-container', {
        duration: 0.5,
        y: 0,
      });
    }

    return t1;
  }, [showCamera, t1]);
  //share text
  const shareText =
    currentLanguage === 'english' ? 'Save to share' : 'Download zum Teilen';
  return (
    <>
      <DownloadImage className='downloadImage'>
        <img src={downloadURLOrginal} className='topImage' alt='text background-1' />
      </DownloadImage>
      <CameraContainer className='camera-container'>
        <div className='share'>
          <div className='share-foto'>
            <img
              src='/img/foto-camera.png'
              className='share-foto-image share-foto-image-1'
              alt='text background'
              onClick={downloadScreenshot}
            />
            <img
              src={imageURLOrginal}
              className='share-foto-image share-foto-image-2'
              alt='text background'
              onClick={handleChoosePhoto}
            />
          </div>
          <h1 className='share-button share-button-text'>Select photo option</h1>
          <div className='share-button share-button-action'>
            {!isIOS && <span onClick={handleShareButton}> Share </span>}
            <a
              href={imageURLOrginal}
              download
              style={
                isIOS ? { width: '100%', padding: '1.5rem .4rem' } : { width: '50%' }
              }
            >
              {isIOS ? shareText : 'Save'}
            </a>
          </div>
          <div className='share-button share-photo-action'>
            {!isIOS && (
              <span onClick={handlePhotoShareButton} className='share-photo-ios'>
                Share
              </span>
            )}
            <a
              href=''
              download
              className='download-camera-image'
              style={
                isIOS ? { width: '100%', padding: '1.5rem .4rem' } : { width: '50%' }
              }
            >
              {isIOS ? shareText : 'Save'}
            </a>
          </div>
        </div>
        <div className='share-cancel' onClick={handleCancelCamera}>
          <h1>Cancel</h1>
        </div>
      </CameraContainer>
    </>
  );
}

export default SocialShare;
