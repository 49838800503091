import Intro2 from '../components/SC/Intro2';

const Screen2 = () => {
  return (
    <>
      <Intro2 />
    </>
  );
};
export default Screen2;
