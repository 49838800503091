import React, { useState, useEffect } from 'react';
import { MenuContainer, LanguageItem } from './styles';
import WebInfo from '../Info';
import { gsap, Back } from 'gsap/all';
import {
  useGlobalDispatchContext,
  useGlobalStateContext,
} from '../../../../utils/globalContext';
import { globalEnglish } from '../../../../utils/locales/en';
import { globalGerman } from '../../../../utils/locales/de';

const Menu = () => {
  const [openPrivacy, setOpenPrivacy] = useState(false);
  const [openImprint, setOpenImprint] = useState(false);

  const dispatch = useGlobalDispatchContext();
  const { currentLanguage } = useGlobalStateContext();
  const t = currentLanguage === 'english' ? globalEnglish : globalGerman;
  const LanguageHandler = () => {
    if (currentLanguage === 'english') {
      dispatch({ type: 'TOGGLE_LANGUAGE', language: 'german' });
    } else {
      dispatch({ type: 'TOGGLE_LANGUAGE', language: 'english' });
    }
  };
  // Website Title
  const websiteTitle = t.siemensWebsite;
  // privacy
  const privacyTitle = t.privacyTitle;
  const privacyText = t.privacyText;
  const privacyButton = t.privacyButton;
  // imprint
  const imprintTitle = t.imprintTitle;
  const imprintText = t.imprintText;
  const imprintContact = t.imprintContact;
  const imprintButton = t.imprintButton;
  const imprintLink =
    currentLanguage === 'english'
      ? 'https://new.siemens.com/global/en/general/legal.html'
      : 'https://new.siemens.com/de/de/general/legal.html';
  const t1 = gsap.timeline({ delay: 0.5 });
  const infoBoxClose = (setState) => {
    gsap.fromTo(
      '.info-container',
      {
        opacity: 1,
        y: 0,
      },
      {
        opacity: 0,
        duration: 0.5,
        y: 100,
        zIndex: 230,
      }
    );
    setTimeout(() => {
      setState(false);
    }, 500);
  };
  const items = [
    {
      id: 1,
      title: privacyTitle,
      src: '/ui/svg/common/close-icon.svg',
      text: privacyText,
      link: 'https://new.siemens.com/global/en/general/privacy-notice.html',
      buttonText: privacyButton,
      contact: '',
      open: () => infoBoxClose(setOpenPrivacy),
    },
    {
      id: 2,
      title: imprintTitle,
      src: '/ui/svg/common/close-icon.svg',
      text: imprintText,
      link: imprintLink,
      buttonText: imprintButton,
      contact: imprintContact,
      open: () => infoBoxClose(setOpenImprint),
    },
    // {
    //   id: 3,
    //   title: 'Imprint',
    //   src: '/ui/svg/common/menu-icon.svg',
    //   link: 'https://www.siemens.com/',
    // },
  ];
  const infoBoxOpen = (t1) => {
    t1.to('.header-nav__burger--line1', 0.2, {
      rotate: '0',
      y: 0,
    });
    t1.to('.header-nav__burger--line2', 0.2, {
      opacity: 1,
    });
    t1.to('.header-nav__burger--line3', 0.2, {
      rotate: '0',
      y: 0,
    });
    t1.to('.menu-container', 0.8, {
      y: '-150%',
      ease: Back.easeInOut.config(1.7),
    });
    document.body.classList.remove('hide');
    t1.fromTo(
      '.info-container',
      {
        opacity: 0,
        y: 100,
      },
      {
        opacity: 1,
        duration: 0.5,
        y: 0,
        zIndex: 230,
      }
    );

    return t1;
  };

  const handlePrivacyBox = () => {
    setOpenPrivacy(true);
    return t1;
  };
  const handleImprintBox = () => {
    setOpenImprint(true);
  };
  useEffect(() => {
    if (openImprint) infoBoxOpen(t1);
    if (openPrivacy) infoBoxOpen(t1);
  }, [openImprint, openPrivacy]);
  useEffect(() => {
    window.localStorage.setItem('language', currentLanguage);
  }, [currentLanguage]);

  return (
    <>
      <MenuContainer className='menu-container'>
        <a
          href='https://www.siemens.com/'
          target='_blank'
          rel='noopener noreferrer'
          className='menu-item'
        >
          <p className='menu-item-title'>{websiteTitle}</p>
          <img
            src='/ui/svg/common/menu-icon.svg'
            className='header-nav__marker'
            alt='header logo'
          />
        </a>
        <div className='menu-item' onClick={handlePrivacyBox}>
          <p className='menu-item-title'>{privacyTitle}</p>
          <img
            src='/ui/svg/common/menu-icon.svg'
            className='header-nav__marker'
            alt='header logo'
          />
        </div>
        <div className='menu-item' onClick={handleImprintBox}>
          <p className='menu-item-title'>{imprintTitle}</p>
          <img
            src='/ui/svg/common/menu-icon.svg'
            className='header-nav__marker'
            alt='header logo'
          />
        </div>
        <LanguageItem>
          <img
            src='/ui/svg/common/internet-icon.svg'
            className='internet-icon'
            alt='header logo'
          />
          <p className='menu-item-title'>Germany</p>
          <div className='select-language'>
            <p
              className='menu-item-title'
              style={
                currentLanguage === 'english' ? { fontWeight: 700 } : { fontWeight: 400 }
              }
              onClick={LanguageHandler}
            >
              EN
            </p>
            <p
              className='menu-item-title'
              style={
                currentLanguage === 'german' ? { fontWeight: 700 } : { fontWeight: 400 }
              }
              onClick={LanguageHandler}
            >
              DE
            </p>
          </div>
        </LanguageItem>
      </MenuContainer>
      {openPrivacy && <WebInfo item={items[0]} />}
      {openImprint && <WebInfo item={items[1]} />}
    </>
  );
};

export default Menu;
