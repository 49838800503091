import React, { useEffect } from 'react';
import { gsap } from 'gsap/all';
import { HeroTextContainer, Hide } from '../../../styles/styles';
import { useGlobalStateContext } from '../../../utils/globalContext';
import { de, globalGerman } from '../../../utils/locales/de';
import { en, globalEnglish } from '../../../utils/locales/en';
import { sceneTwoAnimation } from './animation';
import { ScreenOneContainer, TextContainer, TextContainerTwo } from './styles';

const Intro2 = () => {
  const { currentLanguage, currentStation } = useGlobalStateContext();
  const t =
    currentLanguage === 'english' ? en[currentStation - 1] : de[currentStation - 1];

  useEffect(() => {
    const t1 = gsap.timeline();
    sceneTwoAnimation(t1);
  }, []);
  // Heading Text
  const globalText = currentLanguage === 'english' ? globalEnglish : globalGerman;
  const headingTwo = globalText.headingTwo.split('@');

  // Wishes  Text One and Two
  const wishTextOne = t.wishTextOne;
  const wishTextTwo = t.wishTextTwo;
  return (
    <ScreenOneContainer className='screen-Container-2'>
      <HeroTextContainer className='hero-Container-2'>
        <Hide>
          <h1 className='hero-text hero-text-1'>
            <span>{headingTwo[0]}</span>
          </h1>
        </Hide>
        <Hide>
          <h1 className='hero-text hero-text-2'> {headingTwo[1]}</h1>
        </Hide>
      </HeroTextContainer>
      <TextContainer className='text-container text-container-1'>
        <Hide>
          <h1
            className='title wish-title-1'
            dangerouslySetInnerHTML={{ __html: wishTextOne }}
          />
        </Hide>
      </TextContainer>
      <TextContainerTwo className='text-container text-container-2'>
        <Hide>
          <h1
            className='title wish-title-2'
            dangerouslySetInnerHTML={{ __html: wishTextTwo }}
          />
        </Hide>
      </TextContainerTwo>
    </ScreenOneContainer>
  );
};

export default Intro2;
