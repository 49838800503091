import { createGlobalStyle } from 'styled-components';
import './fonts/SiemensSans_Prof_Bold.ttf';
import './fonts/SiemensSans_Prof_Roman.ttf';
import './fonts/SiemensSans_Prof_Black.ttf';
const GlobalStyle = createGlobalStyle`
 /* *{
   outline: 1px solid red;
 } */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
    --primary: #000028;
    --secondary: #009999;
    --secondary-dark: #007993;
    --secondary-light: #0098A6;
    --tertiary: #00FFB9;
    --lightSand: #F3F3F0;
    --white: #ffffff;
    --bs: 0 12px 24px 0 rgba(0,0,0,0.09);
    --primaryGradient:  linear-gradient(to right, #00FFB9, #00E6DC);
    --secondaryBorder: 1px solid var(--secondary);
    --border: 1px solid var(--secondary-dark);
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-size: 62.5%;  
    @font-face {
    font-family: "SiemensSansProfBold";
    src: local("SiemensSansProfBold"),
    url("/fonts/SiemensSans_Prof_Bold.ttf") format("truetype");
    font-weight: bold;
    }
    @font-face {
    font-family: "SiemensSansProfRoman";
    src: local("SiemensSansProfRoman"),
    url("/fonts/SiemensSans_Prof_Roman.ttf") format("truetype");
    font-weight: 400;
    }
    @font-face {
    font-family: "SiemensSansProfBlack";
    src: local("SiemensSansProfBlack"),
    url("/fonts/SiemensSans_Prof_Black.ttf") format("truetype");
    font-weight: 600;
    }
   }
  
body{
 font-family:"SiemensSansProfBold", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   
  position: absolute;
 
 }
 
h1, h2, h3, h4, h5, h6{
  font-family: 'SiemensSansProfBold', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: var(--primary);
}
ul,
li {
  margin: 0;
  padding: 0;
}
 
::selection {
  background-color: var(--secondary); ;
  color:var(--white);
}
.textCanvas {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  /* background-color: rgba(0, 0, 0, 0.5); */
  z-index: -2;
  opacity: 0 !important;
  pointer-events: none !important;
}
#requestingCameraPermissions {
  color: var(--primary) !important;
  background-color: white !important;
}
#requestingCameraIcon {
  /* This changes the image from white to black */
  filter: invert(1) !important;
}
#loadBackground{
  background-color: var(--primary) !important;
}
#loadImage{
  color: var(--tertiary) !important;
}

.prompt-box-8w {
   background: rgba(255, 255, 255, 0.9) !important;
  border-radius: 0 2rem 2rem 2rem !important;
  padding: 3rem 2rem !important;
  color: var(--primary) !important;

}
.prompt-button-8w {
  font-size: 1.4rem !important;
    letter-spacing: 1.5px !important;
    padding: 0.9rem 3.5rem !important;
    border: var(--border) !important;
    margin-right: 1rem !important;
    color: var(--secondary-dark) !important;
    border-radius: 25rem !important;
    background: none !important;
}

.button-primary-8w {
  font-size: 1.4rem !important;
    letter-spacing: 1.5px !important;
    padding: 0.9rem 3.5rem !important;
    border: var(--border) !important;
    margin-right: 1rem !important;
    color: var(--secondary-dark) !important;
    border-radius: 25rem !important;
    background: none !important;
}
`;

export default GlobalStyle;
