import gsap from 'gsap/gsap-core';
import React, { useEffect } from 'react';
import { Hide } from '../../styles/styles';
import { ErrorContainer } from './styles';

const ErrorScreen = () => {
  useEffect(() => {
    gsap.fromTo(
      'body',
      {
        backgroundColor: '',
      },
      {
        backgroundColor: 'var(--primary',
        duration: 0.001,
      }
    );
    gsap.fromTo(
      '.footer-container',
      {
        display: 'flex',
      },
      {
        display: 'none',
        duration: 0.001,
      }
    );
  }, []);
  return (
    <ErrorContainer>
      {[
        'Uh oh! Sadly your smartphone',
        'doesn’t support all features',
        'necessary for this application.',
      ].map((text, i) => (
        <Hide key={i}>
          <h1 className='title title-language'>{text}</h1>
        </Hide>
      ))}
    </ErrorContainer>
  );
};

export default ErrorScreen;
