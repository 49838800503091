export const globalEnglish = {
  headingOne: "That's @me ...",
  headingTwo: "I'd like to @see ...",
  headingThree: 'My question @to you ...',

  markerAdvice:
    'Follow the markers around the @charging station with your phone @and get to know me.',
  footerText: 'More about me',
  cameraTollTip: 'Share me using @#MoveToTransform',

  siemensWebsite: 'Siemens Website',
  privacyTitle: 'Private Policy',
  privacyText:
    'We (meaning the specific company identified as being the operator of the website that directed you to this Privacy Notice) believe that protecting the security and privacy of your personal data is important. This Privacy Notice explains how we collect, store, use, disclose and transfer (hereinafter “process”) your personal data. The personal data that we collect about you depends on the context of your interactions with us, the products. services and features that you use, your location, and applicable law.',
  privacyButton: 'Read more',
  imprintTitle: 'Corporate Information',
  imprintText:
    'Konzernzentrale @Siemens Aktiengesellschaft @Werner-von-Siemens-Straße 1 @80333 München @Deutschland',
  imprintContact: {
    email: 'contact@siemens.com',
    tel: 'Tel. +49 89 636-00',
    fax: 'Fax +49 69 6682-6664',
  },
  imprintButton: 'Read more',
  questionCameraText: 'Share your thoughts @on social media.',
};

<span style='background-color: #00FFB9'></span>;
export const en = [
  {
    id: 0,
    link: 'mover-alex-bangula',
    bubbleTextOne: "Hi, I'm Alex Bangula.",
    bubbleTextTwo:
      'I share my <span style="background-color: #00FFB9">experiences with &#8203;e-mobility</span> on my YouTube channel.  ',
    bubbleTextThree:
      "That's why I am a <span style='background-color: #00FFB9'>Mobility Mover</span>.",
    wishTextOne:
      'A <span style="background-color: #00FFB9">fact-based</span> mobility debate.',
    wishTextTwo:
      'A well-developed and easily accessible <span style="background-color: #00FFB9">charging network with the same convinience as refuelling.</span>',
    // 'A charging network with the same <span style="background-color: #00FFB9">con&shy;venience as refuell&shy;ing</span>. ',
    questionTextOne:
      'Of course it’s important where raw materials and elect&shy;ricity for e-cars come from <span style="background-color: #00FFB9">but where does our gaso&shy;line actually come from?</span> ',
  },
  {
    id: 1,
    link: 'mover-nari-kahle',
    bubbleTextOne: "Hello, I'm Nari Kahle.",
    bubbleTextTwo:
      'In my new book „Mobilität in Bewegung“, I call for <span style="background-color: #00FFB9">new mobility to be social at all costs.</span>',
    // 'In my book, I call for <span style="background-color: #00FFB9">new mobility to be social at all costs</span>. ',
    bubbleTextThree:
      "That's why I am a <span style='background-color: #00FFB9'>Mobility Mover</span>.",
    wishTextOne:
      'That we don’t tell others <span style="background-color: #00FFB9">what kind of mobility is the right one</span>.',
    wishTextTwo:
      'That we consi&shy;der those <span style="background-color: #00FFB9">who need mobility the most</span>.',
    questionTextOne:
      '<span style="background-color: #00FFB9">How can mobility become better-</span> &#8203;more &#8203;access&shy;ible, more social &#8203;and&#8203; more &#8203;economical? ',
  },
  {
    id: 2,
    link: 'mover-giovane-elber',
    bubbleTextOne: "Hi, I'm Giovane Élber.",
    bubbleTextTwo:
      'As an ambassador for FC Bayern Munich, I want the <span style="background-color: #00FFB9">club to aim for the high&shy;est in terms of sustain&shy;ability as well</span>.',
    bubbleTextThree:
      "That's why I am a <span style='background-color: #00FFB9'>Mobility Mover</span>.",
    wishTextOne:
      ' That in 2030 it’ll be possible to <span style="background-color: #00FFB9">visit a stadium and charge my e-car in the parking garage</span>.',
    wishTextTwo:
      'That <span style="background-color: #00FFB9">people continue to treat each other with the necessary respect</span> in the future - no matter where you come from.',
    questionTextOne:
      "How'd you like to live &#8203;in a world where in <span style='background-color: #00FFB9 '>many countries, it &#8203;gets too&#8203; hot to play &#8203;foot&shy;ball?</span>",
  },
  {
    id: 3,
    link: 'mover-ignacio-diaz',
    bubbleTextOne: "Hey, I'm Ignacio Diaz.",
    bubbleTextTwo:
      'I built the electric car <span style="background-color: #00FFB9">charging infrastructure at the IAA</span> for you.',
    bubbleTextThree:
      "That's why I am a <span style='background-color: #00FFB9'>Mobility Mover</span>.",
    wishTextOne:
      'That the reluct&shy;ance to <span style="background-color: #00FFB9">switch to e-drives</span> is overcome.',
    wishTextTwo:
      '<span style="background-color: #00FFB9">More e-vehicles and charging options</span> – ideally from Siemens.  ',
    questionTextOne:
      'Would you like to live in a world where your <span style="background-color: #00FFB9">"fuel" comes from local resources as green power?</span>',
  },
  {
    id: 4,
    link: 'mover-raul-krauthausen',
    bubbleTextOne: 'Hello, my name is Raul Kraut&shy;hausen.',
    bubbleTextTwo:
      'I support Siemens in developing <span style="background-color: #00FFB9">inclusive &#8203;e-charging stations</span>.',
    bubbleTextThree:
      "That's why I am a <span style='background-color: #00FFB9'>Mobility Mover</span>.",
    wishTextOne:
      '<span style="background-color: #00FFB9">Accessibility as a human right</span> and quality &#8203;critera &#8203;for mobility planning.',
    wishTextTwo:
      'Spontaneous, <span style="background-color: #00FFB9">intermodal travel as an option for all</span> people.',
    questionTextOne:
      'One in 10 people has a disability, <span style="background-color: #00FFB9">how many do you know personally?</span>',
  },
  {
    id: 5,
    link: 'mover-svenja-weiss',
    bubbleTextOne: "Hi, I'm Svenja Weiß.",
    bubbleTextTwo:
      'With "Mobility as a Service," you get <span style="background-color: #00FFB9">access to the mobility you need right now</span>.',
    bubbleTextThree:
      "That's why I am a <span style='background-color: #00FFB9'>Mobility Mover</span>.",
    wishTextOne:
      'That the use of <span style="background-color: #00FFB9">public transportation &#8203;becomes &#8203;"cooler"</span>.',
    wishTextTwo:
      'An awareness of the added <span style="background-color: #00FFB9">quality of life</span> and freedom offered by the <span style="background-color: #00FFB9">flexible use of a wide range of transportation options</span>.',
    questionTextOne:
      'Under what conditions can you imagine <span style="background-color: #00FFB9">a life without &#8203;a car?</span>',
  },
  {
    id: 6,
    link: 'mover-zhang-xi',
    bubbleTextOne: 'Hi there, I am Xi Zhang.',
    bubbleTextTwo:
      'My Job is to &#8203;<span style="background-color: #00FFB9">speed &#8203;up the charging infrastructure</span>.',
    bubbleTextThree:
      "That's why I am a <span style='background-color: #00FFB9'>Mobility Mover</span>.",
    wishTextOne:
      "<span style='background-color: #00FFB9'>Clean air to breath</span> even if I'm biking on “Mittlerer Ring” in Munich.",
    wishTextTwo:
      'That there are more and <span style="background-color: #00FFB9">more people joining the combat against climate change</span> as every action counts.',
    questionTextOne:
      'Can you imagine a future where there is <span style="background-color: #00FFB9">no difference between winter Olympics and summer Olympics?</span>',
  },
  {
    id: 7,
    link: 'mover-anita-mathieu',
    bubbleTextOne: 'Hello, my name is Anita Mathieu.',
    bubbleTextTwo:
      'I contribute to sustain&shy;able mobility by &#8203;work&shy;ing&#8203; on &#8203;<span style="background-color: #00FFB9">intelligent traffic&shy;manage&shy;ment systems</span>.',
    bubbleTextThree:
      "This is why I'm a <span style='background-color: #00FFB9'>Mobility Mover</span>.",
    wishTextOne:
      'That everyone makes <span style="background-color: #00FFB9">climate action a top priority</span>.',
    wishTextTwo:
      'That there’s more visibility and incentives for <span style="background-color: #00FFB9">environment&shy;ally oriented traffic manage&shy;ment</span>.',
    questionTextOne:
      'If you knew that in one year you would be hit by a natural disaster due to climate change, <span style="background-color: #00FFB9">what would you change today, especially with regards to your mobility choices?</span>',
  },
];
