import styled from 'styled-components';

export const Hide = styled.div`
  overflow: hidden;
`;
export const HeroTextContainer = styled.div`
  margin: 1rem auto;
  display: none;
  justify-content: start;
  flex-direction: column;
  text-align: left;
  font-weight: 600;
  top: 14%;
  position: absolute;
  /* transform: translateX('-100px'); */
  @media screen and (min-height: 800px) {
    top: -2%;
  }
  @media screen and (max-height: 667px) {
    top: 19%;
  }
  @media screen and (max-height: 568px) {
    top: 23%;
  }
  /* background: yellow; */
  /* @media screen and (max-height: 720px) {
    top: 18%;
  } */
  h1 {
    display: inline-block;
    background-image: var(--primaryGradient);
    font-size: 1.8rem;
    @media screen and (max-height: 568px) {
      font-size: clamp(1rem, 1.3rem, 1.7rem);
    }
    color: #000;
    margin-bottom: 0.5rem;
    padding: 0.4rem 1rem 0.6rem 2rem;
  }
`;
