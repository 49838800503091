import styled from 'styled-components';

export const ErrorContainer = styled.div`
  background-color: var(--white);
  border-radius: 0 2rem 2rem 2rem;
  padding: 3rem 2rem;
  max-width: 90%;
  margin: 0 auto;
  overflow: hidden;
  position: absolute;
  body {
    background: red !important;
  }
  z-index: 200;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  .title {
    font-size: clamp(1.2rem, 1.8rem, 2rem);
    line-height: 28px;
  }
  .language-container-button {
    padding-top: 2rem;
    display: flex;
    justify-content: start;
    align-items: center;
    background: none;
  }
  .button {
    font-size: 1.4rem;
    letter-spacing: 1.5px;
    padding: 0.9rem 3.5rem;
    border: var(--border);
    margin-right: 1rem;
    color: var(--secondary-dark);
    border-radius: 25rem;
    background: none;
  }
`;
