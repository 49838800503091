import styled from 'styled-components';

export const CameraContainer = styled.div`
  z-index: 300;
  min-height: 30vh;
  width: 90%;
  margin: 1rem auto;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: translateY(60vh);
  overflow: hidden;

  .share {
    background: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    color: var(--secondary);
    padding: 0.5rem;
    margin-bottom: 1rem;
    .share-foto {
      padding: 1.2rem 1rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      &-original {
        position: relative;
      }
      & > * {
        margin: 0 1rem;
      }
      &-image {
        width: clamp(50%, 50%, 50%);
        height: clamp(12rem, 16rem, 17rem);
        border: 3px solid var(--secondary);

        border-radius: 1.5rem;
        /* margin: 0 0.5rem; */
      }
    }
    h1 {
      color: var(--secondary);
      padding: 1.5rem;
      cursor: pointer;
      @media screen and (max-height: 568px) {
        font-size: clamp(1rem, 1.5rem, 1.7rem);
      }
    }
    .share-button-action,
    .share-photo-action {
      display: none;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
      span,
      a {
        width: 50%;
        text-align: center;
        color: var(--secondary);
        padding: 1.5rem;
        cursor: pointer;
        font-size: clamp(1.6rem, 2rem, 2.4rem);
        font-weight: 700;
        text-decoration: none;
        @media screen and (max-height: 568px) {
          font-size: clamp(1rem, 1.5rem, 1.7rem);
        }
      }
    }
    .take-photo-icon {
      position: absolute;
      top: 10%;
      left: 0;
      padding: 6rem 2rem;
      width: 50%;
      opacity: 0;
    }
    &-button-action {
      display: none;
      cursor: pointer;
    }
  }
  .share-cancel {
    background: var(--primary);
    width: 100%;
    height: 100%;
    padding: 2rem 1.5rem;
    cursor: pointer;
    text-align: center;
    h1 {
      color: var(--secondary-light);
      pointer-events: none;
      @media screen and (max-height: 568px) {
        font-size: clamp(1rem, 1.5rem, 1.7rem);
      }
    }
  }

  .download-btns {
    display: none;
  }
`;

export const DownloadImage = styled.div`
  /* display: none; */
  width: 100vw;
  height: 100vh;
  z-index: -1;
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: 'red';

  .topImage {
    z-index: -2;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    object-fit: contain;
  }

  .bottomImage {
    z-index: -3;
  }
`;
